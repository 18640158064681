import React, { Component } from 'react';

class LocationMap extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-lg-12">
                    <h2>Location Address</h2>
                    <p>Butlersland New Ross Co. Wexford Y34 YN56</p>
                        {/* Google map */}
                        <div id="map-container-google-1" className="z-depth-1-half map-container" style={{height: "500px"}}>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4870.483998713034!2d-6.944769967884264!3d52.38416319999557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4842b6c82bf8a72d%3A0x48f9b7db7132d880!2sNew+Ross+Celtic!5e0!3m2!1sen!2sie!4v1547150987292"
                                title="location" frameBorder="0" style={{border:"0"}} allowFullScreen>
                            </iframe>
                        </div>
                        {/* Google Maps */}
                </div>
          </div>
        )
    }
}
export default LocationMap;