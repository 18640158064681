import React from 'react';
import './Resources.css';
import crest from 'img/contact/crest.jpg';

function SocialMediaPolicy() {

  return (
    <div className='container club-resources-container'>
      <div className="resources-crest">
        <div>
          <img src={crest} width="150" height="215" alt="New Ross Celtic Crest" />
        </div>
        <div>
          <strong>Grounds:</strong><br/>Butlersland, New Ross,<br/>Co. Wexford, Y34 YN56
        </div>
      </div>
      <hr/>
      <div>
        <h1>CLUB SOCIAL MEDIA POLICY</h1>
        <p className="club-social-media-content">
          Social Media is an excellent way to promote activities and showcase success of the club. It also helps the club communicate with its members and the general public. Members should use social media in a responsible, respectful and friendly manner. When using sites such as Facebook, Twitter, etc. members should not post negative comments about referees/judges/opponents/teammates/coaches or any other member of their own club or any other club. Cyber/online bullying is completely unacceptable and will not be tolerated, and poor behaviour online will be treated as a breach of the Code of Conduct in real time. Risks associated with social media services include: cyberbullying, grooming and potential abuse by online predators, identity theft, and exposure to inappropriate content. The capabilities of social networking services may increase the risk for sexual exploitation of children and young people. Easy rules to adhere to: If someone has their own personal profile on a social media website, they should make sure that others cannot access any content, media, or information from that profile that: a) They are not happy for others to have access to. b) Which would undermine their position as sports coach/volunteer representing their organisation/club. As a basic rule, if you are not happy with others seeing particular comments, media or information, then simply do not post these materials on a public forum site. When using social media sites the following should be considered: 
          <ul>
            <li>Change your privacy setting on the profile so that only people you have accepted as friends can see your comments. Individuals should lock down their page to non-friends.</li>
            <li>Review who is on your ‘friends list’ on your personal profile. In most situations, you should not accept ‘friend’s requests’ if you do not actually know the person(s) concerned.</li>
            <li>Ensure personal blogs have clear disclaimers that the views expressed are personal and not representative of a particular club or sport.</li>
            <li>Ensure that information published on social media sites complies with the club/organisations Code of Conduct.</li>
            <li>Beware of how your actions could be captured via images, posts or comments online as these will reflect on the sport or your club.</li>
            <li>Respond to online bullying - what is said online should be treated as if said in real time.</li>
            <li>Those in authority should not have under 18’s they coach as their friends and do not comment on individual players you are coaching through your personal page. Principles If you are representing the club in an official capacity, it is important that your posts convey the same positive spirit as the club ethos would instil in all of its communications. Be respectful of all individuals, races, religions and cultures. How you conduct yourself online not only reflects on you; it also reflects directly on the club. For further information on social media in relation to safeguarding, please see the FAI’s social media policy. <a href="https://www.fai.ie" target="_blank">www.fai.ie</a></li>
          </ul>
        </p>
      </div>
    </div>
  );
}

export default SocialMediaPolicy;