import React from 'react';
import './Shop.css';
import {CardGroup, Card, Modal, Button} from 'react-bootstrap';
import SizeGuide from './SizeGuide';
import img1 from 'img/shop/backpack.jpg';
import img2 from 'img/shop/trainingtop.jpg';
import img3 from 'img/shop/halfzip.jpg';
import img4 from 'img/shop/windcheater.jpg';
import img5 from 'img/shop/academy-pack.jpg';
import img6 from 'img/shop/pants.jpg';
import img7 from 'img/shop/shorts-socks.jpg';
import img8 from 'img/shop/shower-jacket.jpg';
import img9 from 'img/shop/gilet.jpg';

const footerText1 = 'To Order, please contact Richy +353 86 031 7672';
const footerText2 = '*** \u20AC5 delivery charge on all orders ***';

function SizeGuideModal(props) {
  return (
    <Modal
      animation={false}
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Size Guide
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SizeGuide />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function Shop() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div className='container'>
      <div className='shop-container'>
        <div className='shop-link'><a href='https://jako.ie/collections/new-ross-celtic-a-f-c' target='_blank'>Click here to Visit Shop</a></div>
      </div>
      {/*
      <CardGroup>
        <Card className='shop-card'>
          <Card.Img variant="top" src={img5} />
          <Card.Body>
            <Card.Title>Academy Pack</Card.Title>
            <Card.Text>
              Red Long Sleeved Top<br/>
              Red Training Top<br/>
              Black Shorts<br/>
              Black Socks<br/>
              Black Windcheater Top
            </Card.Text>
            <Card.Text>
              Size: Kids (SB, MB, LB)<br/>
              <Button variant="primary" onClick={() => setModalShow(true)}>Size Guide</Button>
              <SizeGuideModal show={modalShow} onHide={() => setModalShow(false)} />
            </Card.Text>
            <Card.Text>
              <strong>&euro;75</strong>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted"><strong>{footerText1}</strong><br/>{footerText2}</small>
          </Card.Footer>
        </Card>
        <Card className='shop-card'>
          <Card.Img variant="top" src={img2} />
          <Card.Body>
            <Card.Title>Training Top</Card.Title>
            <Card.Text>
              Training Jersey/Shirt - Red
            </Card.Text>
            <Card.Text>
              Size: Kids (SB, MB, LB)<br/>Adult: (XLB, S, M, L, XL, XXL)<br/>
              <Button variant="primary" onClick={() => setModalShow(true)}>Size Guide</Button>
              <SizeGuideModal show={modalShow} onHide={() => setModalShow(false)} />
            </Card.Text>
            <Card.Text>
              <strong>Kids - &euro;20</strong><br/>
              <strong>Adults - &euro;25</strong>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted"><strong>{footerText1}</strong><br/>{footerText2}</small>
          </Card.Footer>
        </Card>
        <Card className='shop-card'>
          <Card.Img variant="top" src={img3} />
          <Card.Body>
            <Card.Title>Half Zip</Card.Title>
            <Card.Text>
              Half Zip - Red - Wear New Ross Celtic top with pride.
            </Card.Text>
            <Card.Text>
              Size: Kids (SB, MB, LB)<br/>Adult: (XLB, S, M, L, XL, XXL)<br/>
              <Button variant="primary" onClick={() => setModalShow(true)}>Size Guide</Button>
              <SizeGuideModal show={modalShow} onHide={() => setModalShow(false)} />
            </Card.Text>
            <Card.Text>
              <strong>Kids - &euro;30</strong><br/>
              <strong>Adults - &euro;35</strong>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted"><strong>{footerText1}</strong><br/>{footerText2}</small>
          </Card.Footer>
        </Card>
        <Card className='shop-card'>
          <Card.Img variant="top" src={img6} />
          <Card.Body>
            <Card.Title>Tracksuit Bottoms/Pants</Card.Title>
            <Card.Text>
            Club Crested.<br/>Fabrication: 100% Polyester with Inner drawcord, Side pockets, Open hem with zip. Elasticated waistband and Tapered leg
            </Card.Text>
            <Card.Text>
              Size: Kids (SB, MB, LB)<br/>Adult: (XLB, S, M, L, XL, XXL)<br/>
              <Button variant="primary" onClick={() => setModalShow(true)}>Size Guide</Button>
              <SizeGuideModal show={modalShow} onHide={() => setModalShow(false)} />
            </Card.Text>
            <Card.Text>
              <strong>Kids - &euro;30</strong><br/>
              <strong>Adults - &euro;35</strong>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted"><strong>{footerText1}</strong><br/>{footerText2}</small>
          </Card.Footer>
        </Card>
        <Card className='shop-card'>
          <Card.Img variant="top" src={img7} />
          <Card.Body>
            <Card.Title>Shorts/Socks</Card.Title>
            <Card.Text>
              Black Shorts<br/>
              Black Socks
            </Card.Text>
            <Card.Text>
              Size: Kids (SB, MB, LB)<br/>Adult: (XLB, S, M, L, XL, XXL)<br/>
              <Button variant="primary" onClick={() => setModalShow(true)}>Size Guide</Button>
              <SizeGuideModal show={modalShow} onHide={() => setModalShow(false)} />
            </Card.Text>
            <Card.Text>
              <strong>Kids Socks - &euro;5</strong><br/>
              <strong>Adults Socks - &euro;6</strong><br/>
              <strong>Kids Shorts - &euro;15</strong><br/>
              <strong>Adults Shorts - &euro;15</strong>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted"><strong>{footerText1}</strong><br/>{footerText2}</small>
          </Card.Footer>
        </Card>
        <Card className='shop-card'>
          <Card.Img variant="top" src={img4} />
          <Card.Body>
            <Card.Title>WindCheater</Card.Title>
            <Card.Text>
              WindCheater/WindBreaker - Black/Red - The Umbro Windbreaker jacket features a lightweight construction with breathable ventilation built in.
              Elasticated Cuff and Hem with Two side pockets and Mesh Lining.
            </Card.Text>
            <Card.Text>
              Size: Kids (SB, MB, LB)<br/>Adult: (XLB, S, M, L, XL, XXL)<br/>
              <Button variant="primary" onClick={() => setModalShow(true)}>Size Guide</Button>
              <SizeGuideModal show={modalShow} onHide={() => setModalShow(false)} />
            </Card.Text>
            <Card.Text>
              <strong>Kids - &euro;25</strong><br/>
              <strong>Adults - &euro;25</strong>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted"><strong>{footerText1}</strong><br/>{footerText2}</small>
          </Card.Footer>
        </Card>
        <Card className='shop-card'>
          <Card.Img variant="top" src={img8} />
          <Card.Body>
            <Card.Title>Shower Jacket</Card.Title>
            <Card.Text>
              Red Long Sleeved Shower/Rain Jacket
            </Card.Text>
            <Card.Text>
              Size: Kids (SB, MB, LB)<br/>Adult: (XLB, S, M, L, XL, XXL)<br/>
              <Button variant="primary" onClick={() => setModalShow(true)}>Size Guide</Button>
              <SizeGuideModal show={modalShow} onHide={() => setModalShow(false)} />
            </Card.Text>
            <Card.Text>
              <strong>Kids - &euro;35</strong><br/>
              <strong>Adults - &euro;40</strong>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted"><strong>{footerText1}</strong><br/>{footerText2}</small>
          </Card.Footer>
        </Card>
        <Card className='shop-card'>
          <Card.Img variant="top" src={img9} />
          <Card.Body>
            <Card.Title>Gilet</Card.Title>
            <Card.Text>
              Black Gilet with zip pockets
            </Card.Text>
            <Card.Text>
              Adult: (S, M, L, XL, XXL)<br/>
              <Button variant="primary" onClick={() => setModalShow(true)}>Size Guide</Button>
              <SizeGuideModal show={modalShow} onHide={() => setModalShow(false)} />
            </Card.Text>
            <Card.Text>
              <strong>Adults - &euro;60</strong>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted"><strong>{footerText1}</strong><br/>{footerText2}</small>
          </Card.Footer>
        </Card>
        <Card className='shop-card'>
        */}
          {/*<Card.Img variant="top" width="400px" height="600px" src={img1} />*/}
          {/*}
          <Card.Img variant="top" src={img1} />
          <Card.Body>
            <Card.Title>Backpack</Card.Title>
            <Card.Text>
              Specifications: Polyester 84%, Nylon 16% Features: Front pocket with wallet pocket. 2 side pockets, adjustable shoulder straps. Size: 30 Litres L33xD17xH44
            </Card.Text>
            <Card.Text>
              Size: L<br/>
              <Button variant="primary" onClick={() => setModalShow(true)}>Size Guide</Button>
              <SizeGuideModal show={modalShow} onHide={() => setModalShow(false)} />
            </Card.Text>
            <Card.Text>
            <strong>&euro;25</strong>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted"><strong>{footerText1}</strong><br/>{footerText2}</small>
          </Card.Footer>
        </Card>
      </CardGroup>
    */}
      </div>
  );
}

export default Shop;