import React from 'react';
import { Route, Link, Redirect } from "react-router-dom";
import Userfront from "@userfront/react";
import './Dashboard.css';
import AdminHeaderBanner from 'components/Admin/Common/AdminHeaderBanner/AdminHeaderBanner';

export default function Dashboard() {

  function renderFn({ location }) {
    // If the user is not logged in, redirect to login
    if (!Userfront.accessToken()) {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: location },
          }}
        />
      );
    }

    // If the user is logged in, show the dashboard
    const userData = JSON.stringify(Userfront.user, null, 2);
    return (
      <div className='dashboard'>
        <AdminHeaderBanner text="Admin Area" />
        <pre>{userData}</pre>
        <div className='dashboard__links'>
          <ul>
            <li>
              <Link to='/addNewsItem'>Add News Item (Match Report)</Link>
            </li>
            <li>
              <Link to='/recordAttendance'>Record Attendance</Link>
            </li>
            <li>
              <Link to='/viewAttendance'>View Attendance</Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  return <Route render={renderFn} />;
}