import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
// Authentication Service from UserFront
import Userfront from "@userfront/react";

// Components
import Gallery from 'components/Gallery/Gallery';
import Home from 'components/Home';
import GalleryOne from 'components/Gallery/MickBreen/GalleryOne';
import GalleryTwo from 'components/Gallery/MickBreen/GalleryTwo';
import GalleryThree from 'components/Gallery/MickBreen/GalleryThree';
import Contact from 'components/Contact/Contact';
import Shop from 'components/Shop/Shop';
import Qualifications from 'components/Qualifications/Qualifications';
import Honours from 'components/Honours/Honours-year-ascending'
import ClubEthosStatement from 'components/Resources/ClubEthosStatement';
import SocialMediaPolicy from 'components/Resources/SocialMediaPolicy';
import CodeOfConductCoaches from 'components/Resources/CodeOfConductCoaches';
import CodeOfConductPlayers from 'components/Resources/CodeOfConductPlayers';
import CodeOfConductParents from 'components/Resources/CodeOfConductParents';
import Navigation from 'components/Navigation';
import Footer from 'components/Footer';
// Admin Screens
import Dashboard from 'components/Admin/Dashboard/Dashboard';
import RecordAttendance from 'components/Admin/RecordAttendance/RecordAttendance';
import ViewAttendance from 'components/Admin/RecordAttendance/ViewAttendance';
import AddNewsItem from 'components/Admin/NewsItem/AddNewsItem';
//import Login from 'components/Login/Login';

// Services
//import loginModel from 'models/LoginModel/LoginModel';
import RecordAttendanceModel from 'models/RecordAttendanceModel/RecordAttendanceModel';
import CommonModel from 'models/CommonModel/CommonModel';
import NewsItemModel from 'models/NewsItemModel/NewsItemModel';

// Context section
import { RossCelticContext } from "./ContextApi";

// Injitialise and create UserFront Authentication Components
Userfront.init("6bg6xyn7");
console.log("APP USER IS : ", Userfront.user);
console.log("REACT Environment is : ", process.env.NODE_ENV);
const LoginForm = Userfront.build({
  toolId: "oammln"
});
const SignupForm = Userfront.build({
  toolId: "aaddbk",
});
const PasswordResetForm = Userfront.build({
  toolId: "rmllnr"
});

function App() {
    // States space
    //const {loggedIn, ...loginService} = loginModel();
    const {...recordAttendanceService} = RecordAttendanceModel();
    const {...commonService} = CommonModel();
    const {...newsItemService} = NewsItemModel();

    // View Space
    return (
        <div className="App">
            {/*<RossCelticContext.Provider value={{ loggedIn, loginService }}>*/}
            <RossCelticContext.Provider value={{ recordAttendanceService, commonService, newsItemService }}>
                <Router>
                    <Navigation />
                    <Switch>
                        <Route path="/" exact><Home /></Route>
                        <Route path="/gallery" exact><Gallery /></Route>
                        <Route path="/galleryOne" exact><GalleryOne /></Route>
                        <Route path="/galleryTwo" exact><GalleryTwo /></Route>
                        <Route path="/galleryThree" exact><GalleryThree /></Route>
                        <Route path="/contact" exact><Contact /></Route>
                        <Route path="/shop" exact><Shop /></Route>
                        <Route path="/qualifications" exact><Qualifications /></Route>
                        <Route path="/clubHonours" exact><Honours /></Route>
                        <Route path="/clubEthosStatement" exact><ClubEthosStatement /></Route>
                        <Route path="/socialMediaPolicy" exact><SocialMediaPolicy /></Route>
                        <Route path="/codeOfConductCoaches" exact><CodeOfConductCoaches /></Route>
                        <Route path="/codeOfConductPlayers" exact><CodeOfConductPlayers /></Route>
                        <Route path="/codeOfConductParents" exact><CodeOfConductParents /></Route>
                        <Route path="/login" exact><LoginForm /></Route>
                        <Route path="/reset" exact><PasswordReset /></Route>
                        <Route path="/signup" exact><SignUp /></Route>
                        <Route path="/dashboard" exact><DashboardCheck /></Route>
                        <Route path="/recordAttendance" exact><RecordAttendanceCheck /></Route>
                        <Route path="/viewAttendance" exact><ViewAttendanceCheck /></Route>
                        <Route path="/addNewsItem" exact><AddNewsItemCheck /></Route>
                    </Switch>
                    <Footer />
                </Router>
            </RossCelticContext.Provider>
        </div>
    );
}

/**
 * Shows UserFront password reset panel/service
 * @returns Returns Password Reset Panel
 */
function PasswordReset() {
    return (
        <div>
            <PasswordResetForm />
        </div>
    );
}

/**
 * Shows UserFront sign up panel/service
 * @returns Returns Signup Panel
 */
 function SignUp() {
    return (
        <div>
            <SignupForm />
        </div>
    );
}

/**
 * This will perform check to ensure user is logged in and only then show panel
 * @returns Dashboard Panel
 */
function DashboardCheck() {
    function renderFn({ location }) {
        // If the user is not logged in, redirect to login
        if (!Userfront.accessToken()) {
            return (
                <Redirect
                to={{
                    pathname: "/login",
                    state: { from: location },
                }}
                />
            );
        }

        return (
            <Dashboard />
        );
    }

    return <Route render={renderFn} />;
}

/**
 * This will perform check to ensure user is logged in and only then show panel
 * @returns Record Attendance Panel
 */
function RecordAttendanceCheck() {
    function renderFn({ location }) {
        // If the user is not logged in, redirect to login
        if (!Userfront.accessToken()) {
            return (
                <Redirect
                to={{
                    pathname: "/login",
                    state: { from: location },
                }}
                />
            );
        }

        return (
            <RecordAttendance />
        );
    }

    return <Route render={renderFn} />;
}

/**
 * This will perform check to ensure user is logged in and only then show panel
 * @returns View Attendance Panel
 */
 function ViewAttendanceCheck() {
    function renderFn({ location }) {
        // If the user is not logged in, redirect to login
        if (!Userfront.accessToken()) {
            return (
                <Redirect
                to={{
                    pathname: "/login",
                    state: { from: location },
                }}
                />
            );
        }

        return (
            <ViewAttendance />
        );
    }

    return <Route render={renderFn} />;
}

/**
 * This will perform check to ensure user is logged in and only then show panel
 * @returns Add News Item Panel
 */
 function AddNewsItemCheck() {
    function renderFn({ location }) {
        // If the user is not logged in, redirect to login
        if (!Userfront.accessToken()) {
            return (
                <Redirect
                to={{
                    pathname: "/login",
                    state: { from: location },
                }}
                />
            );
        }

        return (
            <AddNewsItem />
        );
    }

    return <Route render={renderFn} />;
}

export default App;