import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './Gallery.css';

export default class Gallery extends Component {

  render() {
    
    return (
      <div className='gallery'>
        <span className='gallery-span'>Photos Courtesy of Mick Breen</span>
        <div className='gallery__links'>
          <ul>
            <li>
              <Link to='/galleryOne'>Photos From Mick Breen (Part 1)</Link>
            </li>
            <li>
              <Link to='/galleryTwo'>Photos From Mick Breen (Part 2)</Link>
            </li>
            <li>
              <Link to='/galleryThree'>Photos From Mick Breen (Part 3)</Link>
            </li>
          </ul>
        </div>
        </div>
    );
  }
}