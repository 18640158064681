import React, {useState, useRef, useEffect, useContext} from 'react';
import './AddNewsItem.css';
import AdminHeaderBanner from 'components/Admin/Common/AdminHeaderBanner/AdminHeaderBanner';
import { RossCelticContext } from 'ContextApi';
import Select from 'react-select';

let clubSectionOptions = [];
let season = 'EMPTY';

function AddNewsItem() {

    const { newsItemService } = useContext(RossCelticContext);
    const { commonService } = useContext(RossCelticContext);
    const refFetchSeasonFromApi = useRef(commonService);
    const refFetchClubSectionsFromApi = useRef(commonService);
    const refInsertNewsItemRecord = useRef(newsItemService);

    const [selectedClubSectionValue, setSelectedClubSectionValue] = useState(0);
    const [shortDescValue, setShortDescValue] = useState('');
    const [longDescValue, setLongDescValue] = useState('');

    /**
     * Set the Club Section value selected in drop down.
     * @param {Dropdown element}} e 
     */
    const handleClubSectionValueChange = e => {
        // resetDisplayValues();
        setSelectedClubSectionValue(e.value);
    }

    const handleShortDescChange = e => {
        // console.log('setting short desc as : ' + e.target.value);
        setShortDescValue(e.target.value);
    }

    const handleLongDescChange = e => {
        setLongDescValue(e.target.value);
    }

    // Reset Display values
    const resetDisplayValues = function() {
        setSelectedClubSectionValue(0);
        setShortDescValue('');
        setLongDescValue('');
    }

    // controller space
    useEffect(onRender, []);

    function onRender() {
        //
        // THE FOLLOWING WILL BE LOADED EVERYTIME ANY ACTION IS CARRIED OUT ON PAGE.
        // MIGHT WANT TO LOOK TO SEE IF IT CAN ONLY BE DONE ONCE ON PAGE LOAD
        //
        // Load current season to use when storing records
        refFetchSeasonFromApi.current.loadSeasonApi()
        .then((result) => {
            console.log('Seasons results : ', result);
            if (result != null && result.data != null && result.data.length < 2
                && result.data[0].name != null) {
                console.log('Found Season : ', result.data[0].name);
                season = result.data[0].name;
            }
        })
        .then(result => {
            // Load List of Clun Sections assuming season was loaded
            if (season === null || season === 'EMPTY') {
              alert("Please contact Administrator as season was not loaded.");
            }
            else {
              refFetchClubSectionsFromApi.current.loadClubSectionsApi()
              .then((result) => {
                //console.log('Result is : ', result);
                if (result && result.data) {
                  if (result.data.result && result.data.result === 'false') {
                    //console.log('Result = ', result.data.result)
                    alert("Please contact Administrator. " + result.data.message);
                  } else {
                    result.data.forEach(f => {
                        clubSectionOptions.push({ value: f.constantKey, label: f.constantValue});
                    });
                  }
                }
              })
              .catch(err => {
                  // TODO - deal with error
                  console.error("Error Loading Club Sections : ", err);
                  alert("Please contact Administrator as there was an issue loading club sections.");
              })
            }
        })
        .catch(err => {
            // TODO - deal with error
            console.error("Error Loading Season : ", err);
            alert("Please contact Administrator as there was an issue loading season.");
        })
    };

    function onClickAddNewsItem() {
        // Check to ensure Club Section has been selected
        // console.log('club section is : ', selectedClubSectionValue + ' and heading is : ' + shortDescValue + ' and long desc is : ' + longDescValue);

        if (selectedClubSectionValue == null || selectedClubSectionValue === '' || selectedClubSectionValue === 0) {
            alert('Club Section is empty. Please select and try again');
            return;
        }
        else if (shortDescValue == null || shortDescValue === '') {
            alert('Heading is empty. Please enter a heading description and try again');
            return;
        }
        else if (longDescValue == null || longDescValue === '') {
            alert('News Report is empty. Please enter a News Report and try again');
            return;
        }
        else {
            let longDesc = longDescValue.replaceAll('\n', '</p><p>');
            longDesc = '<p>' + longDesc + '</p>';

            const insertRecord = {
                inputs: {
                    shortDesc: shortDescValue,
                    longDesc: longDesc,
                    clubType: selectedClubSectionValue,
                    season: season
                }
            };
            // Save News Item to Database
            refInsertNewsItemRecord.current.addNewsItemRecord(insertRecord)
              .then((result) => {
                // TODO - When saving a News Item record then disable Submit button so it doesn't get added twice
                if (result && result.data && result.data.result && result.data.result === 'false') {
                    alert("**** ERROR Adding News Item. Please try again or contact Administrator.");
                    return;
                }
                resetDisplayValues();
                alert("Added News Item Record.");
              })
              .catch(err => {
                // TODO - deal with error
                alert("There was a problem Adding news item record. Please try again later or contact Administrator");
              });
        }
    }

    return (
        <div className='add-news-item'>
            <AdminHeaderBanner text="Add News Item" />
            <div className='add-news-item-table'>
                <div className='add-news-item-row'>
                    <div className='add-news-item-row-label'>
                        Select Club Section :
                    </div>
                    <div className='add-news-item-row-item add-news-item-row-item-team'>
                        <Select placeholder="Select Option"
                        value={clubSectionOptions.find(obj => obj.value === selectedClubSectionValue)} // set selected value
                        options={clubSectionOptions} // set list of the data
                        onChange={handleClubSectionValueChange} // assign onChange function
                        />
                    </div>
                </div>
                <div className='add-news-item-row'>
                    <div className='add-news-item-row-label'>
                        Heading : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    <div className='add-news-item-row-item'>
                        <textarea className='add-news-item-row-item--heading-textarea' value={shortDescValue} onChange={handleShortDescChange} maxLength='290' />
                    </div>
                </div>
                <div className='add-news-item-row'>
                    <div className='add-news-item-row-label'>
                        News Report :
                    </div>
                    <div className='add-news-item-row-item'>
                        <textarea className='add-news-item-row-item--report-textarea' value={longDescValue} onChange={handleLongDescChange} maxLength='4900' />
                    </div>
                </div>
                <div className='add-news-item-row'>
                    <div className='add-news-item-row-label'></div>
                    <div className='add-news-item-row-item'>
                        <input type="button" className="add-news-item-btn" onClick={() => onClickAddNewsItem()} value="Add News Item" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddNewsItem;