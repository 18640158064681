import React from 'react';
import './Resources.css';
import crest from 'img/contact/crest.jpg';

function CodeOfConductParents() {

  return (
    <div className='container club-resources-container'>
      <div className="resources-crest">
        <div>
          <img src={crest} width="150" height="215" alt="New Ross Celtic Crest" />
        </div>
        <div>
          <strong>Grounds:</strong><br/>Butlersland, New Ross,<br/>Co. Wexford, Y34 YN56
        </div>
      </div>
      <hr/>
      <div>
        <h1>PARENTS CODE OF CONTACT</h1>
        <p className="club-social-media-content">
          We all have a responsibility to promote high standards of behaviour in the game. Parents have a significant influence on children’s enjoyment and success in football. Children play football because they first and foremost love the game – it’s FUN. Remember children’s football is a time for them to develop and learn. We want them to win but not at all costs. Play your part by supporting your child, supporting the FAI’s Player Development Plan and observe the Code of Conduct at all times. I will:
          <ul>
            <li>Remember that children play for FUN.</li>
            <li>Applaud effort and good play.</li>
            <li>Always respect the match officials’ decisions.</li>
            <li>Remain outside the field of play or within the Designated Spectators’ Areas.</li>
            <li>Let the coach do their job and not confuse the players by telling them what to do.</li>
            <li>Encourage your child, and others, or be quiet. Praise effort and performance, not results.</li>
            <li>Encourage the players to respect the opposition, referee and officials.</li>
            <li>Avoid criticising a player for making a mistake – mistakes are part of learning.</li>
            <li>Never engage in, or tolerate, offensive, insulting, or abusive language or behaviour.</li>
            <li>I must develop an appropriate supportive relationship with both the coach and my child based on mutual trust and respect.</li>
            <li>Support the coach’s decisions and discuss any concerns directly with the coach.</li>
            <li>Watch and be familiar with the Coach/Parents Education Video on www.fai.ie. I understand that if I do not follow the Code of Conduct, action may be taken by the club, league or National Association in line with the disciplinary process.</li>
          </ul>
        </p>
      </div>
    </div>
  );
}

export default CodeOfConductParents;