import React, { useState, useEffect } from 'react';
import '../App.css';
import CarouselMainPage from './carouselMainPage';
import NewsItems from './newsItems';
import Facebook from './facebook';
import LocationMap from './locationMap';
import SponsorPanels from './sponsorPanels';
import SponsorPanelsMini from './sponsorPanelsMini';

export default function Home() {
    const [result, setResult] = useState('');

    useEffect(onRender, []);

    function onRender() {
      fetch("api/hello")
      .then(function(response) {
          if (!response.ok) {
              throw Error(response.statusText);
          }
          return response;
      })
      .then(response => response.json())
      .then(text => setResult(text))
      .catch((error) => {
          setResult('API ERROR');
          console.log('Error found ', error);
      });
    }

    return (
        <div>
        <div id="fb-root"></div>
        {(function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = 'https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v3.2';
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'))
        }
        <div className="home">
            <header className="App-header">
                <CarouselMainPage />
            </header>
            {/* Page Content */}
            <div className="container">
            <h1 className="my-4">New Ross Celtic</h1>

            {/* Marketing Icons Section --> */}
            <div className="row">
                <Facebook />
                <div className="col-lg-8 mb-8">
                <div className="card h-100">
                    <h4 className="card-header">News Items</h4>
                    {/* Bootstrap Collapse */}
                    <div id="accordion">
                    <NewsItems news={result} />
                    </div>
                    <div className="card-footer">
                    <a href="/allNews" className="btn btn-primary">View All News Posts</a>
                    </div>
                </div>
                </div>
            </div>
            {/* /.row --> */}

            {/* Portfolio Section */}
            <h2>SPONSORS</h2>
            <SponsorPanels />
            {/* /.row */}

            {/* Location Section */}
            <LocationMap />
            {/* /.row */}

            <hr/>

            {/* Sponsors Panel of mini logos */}
            <h2>SCHOOL GIRL TEAMS SPONSORS</h2>
            <SponsorPanelsMini />
            {/* /.row */}

            <hr/>

            {/* Call to Action Section
            <div className="row mb-4">
                <div className="col-md-8">
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, expedita, saepe, vero rerum deleniti beatae veniam harum neque nemo praesentium cum alias asperiores commodi.</p>
                </div>
                <div className="col-md-4">
                <a className="btn btn-lg btn-secondary btn-block" href="#">Call to Action</a>
                </div>
            </div>
            */}
            {/* END OF PAGE CONTENT DIV CONTAINER SECTION */}
            </div>
        </div>
        </div>
    )
}