import React from "react";
import './Qualifications.css';
import crest from 'img/contact/crest.jpg';
import qualificationsImg from 'img/qualifications/qualifications.png';

function Qualifications() {
  return (
    <div className="qualifications">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-6">
            <img className="img-fluid rounded mb-4 mb-lg-0"
                 src={crest} alt="New Ross Celtic Crest"
            />
          </div>
          <div className="col-lg-6">
            <img className="img-fluid rounded mb-4 mb-lg-0"
                 src={qualificationsImg} alt="Coaching Qualifications"
            />
          </div>
        </div>
        <div className="qualifications-table">
          <div className="qualifications__table__row">
            <div className="qualifications__cell qualifications__cell--shaded">NAME</div>
            <div className="qualifications__cell qualifications__cell--shaded">QUALIFICATIONS</div>
          </div>
          <div className="qualifications__table__row">
            <div className="qualifications__cell">Ger Feeney</div>
            <div className="qualifications__cell">&bull;Garda Vetting<br/>&bull;SafeGuarding 1<br/>&bull;Safeguarding 3<br/>&bull;Player Development Pathway 1<br/>&bull;Player Development Pathway 2<br/>
            &bull;National C License<br/>&bull;Futsal Introduction<br/>&bull;UEFA Grassroots Leadership<br/>&bull;Match Preparation and Nutrition Workshop<br/>&bull;Awareness and Coaching in Disability Football<br/>
            &bull;Ohana Suicide Awareness Course</div>
          </div>
          <div className="qualifications__table__row">
            <div className="qualifications__cell">Martin O&apos;Sullivan</div>
            <div className="qualifications__cell">&bull;Garda Vetting<br/>&bull;SafeGuarding 1<br/>&bull;Player Development Pathway 1<br/>&bull;Player Development Pathway 2<br/>
            &bull;Player Development Pathway 3<br/>&bull;4v4 Workshop<br/>&bull;Ohana Suicide Awareness Course</div>
          </div>
          <div className="qualifications__table__row">
            <div className="qualifications__cell">Robbie Power</div>
            <div className="qualifications__cell">&bull;Garda Vetting<br/>&bull;SafeGuarding 1<br/>&bull;Player Development Pathway 1<br/>&bull;Player Development Pathway 2<br/>&bull;Player Development Pathway 3</div>
          </div>
          <div className="qualifications__table__row">
            <div className="qualifications__cell">Tommy McGarr</div>
            <div className="qualifications__cell">&bull;Garda Vetting<br/>&bull;SafeGuarding 1<br/>&bull;Player Development Pathway 1<br/>&bull;Player Development Pathway 2<br/>
            &bull;Player Development Pathway 3<br/>&bull;Ohana Suicide Awareness Course</div>
          </div>
          <div className="qualifications__table__row">
            <div className="qualifications__cell">Karen Jones</div>
            <div className="qualifications__cell">&bull;Garda Vetting<br/>&bull;SafeGuarding 1<br/>&bull;Player Development Pathway 1<br/>&bull;Player Development Pathway 2<br/>
            &bull;Player Development Pathway 3<br/>&bull;Ohana Suicide Awareness Course</div>
          </div>
          <div className="qualifications__table__row">
            <div className="qualifications__cell">Ethan Butler</div>
            <div className="qualifications__cell">&bull;Garda Vetting<br/>&bull;SafeGuarding 1<br/>&bull;Player Development Pathway 1<br/>&bull;Player Development Pathway 2<br/>
            &bull;Player Development Pathway 3<br/>&bull;Ohana Suicide Awareness Course</div>
          </div>
          <div className="qualifications__table__row">
            <div className="qualifications__cell">Brendan Keane</div>
            <div className="qualifications__cell">&bull;Garda Vetting<br/>&bull;SafeGuarding 1<br/>&bull;Player Development Pathway 1<br/>&bull;Player Development Pathway 2<br/>
            &bull;Player Development Pathway 3</div>
          </div>
          <div className="qualifications__table__row">
            <div className="qualifications__cell">Ivan Dunne</div>
            <div className="qualifications__cell">&bull;Garda Vetting<br/>&bull;SafeGuarding 1<br/>&bull;Player Development Pathway 1<br/>&bull;Player Development Pathway 2<br/>
            &bull;Ohana Suicide Awareness Course</div>
          </div>
          <div className="qualifications__table__row">
            <div className="qualifications__cell">Giedrius Zagurskis</div>
            <div className="qualifications__cell">&bull;Garda Vetting<br/>&bull;SafeGuarding 1<br/>&bull;Player Development Pathway 1<br/>&bull;Player Development Pathway 2</div>
          </div>
          <div className="qualifications__table__row">
            <div className="qualifications__cell">Richy Walsh</div>
            <div className="qualifications__cell">&bull;Garda Vetting<br/>&bull;SafeGuarding 1<br/>&bull;Player Development Pathway 1<br/>&bull;Player Development Pathway 2<br/>
            &bull;Ohana Suicide Awareness Course</div>
          </div>
          <div className="qualifications__table__row">
            <div className="qualifications__cell">James Kehoe</div>
            <div className="qualifications__cell">&bull;Garda Vetting<br/>&bull;SafeGuarding 1<br/>&bull;Player Development Pathway 1<br/>&bull;Player Development Pathway 2<br/>
            &bull;Player Development Pathway 3</div>
          </div>
          <div className="qualifications__table__row">
            <div className="qualifications__cell">Abby O&apos;Sullivan</div>
            <div className="qualifications__cell">&bull;Garda Vetting<br/>&bull;SafeGuarding 1<br/>&bull;Player Development Pathway 1<br/>&bull;Player Development Pathway 2</div>
          </div>
          <div className="qualifications__table__row">
            <div className="qualifications__cell">Catherine Barrett</div>
            <div className="qualifications__cell">&bull;Garda Vetting<br/>&bull;SafeGuarding 1<br/>&bull;Player Development Pathway 1</div>
          </div>
          <div className="qualifications__table__row">
            <div className="qualifications__cell">Matej Stephanek</div>
            <div className="qualifications__cell">&bull;Garda Vetting<br/>&bull;SafeGuarding 1<br/>&bull;Player Development Pathway 1</div>
          </div>
          <div className="qualifications__table__row">
            <div className="qualifications__cell">Keith Katus</div>
            <div className="qualifications__cell">&bull;Garda Vetting<br/>&bull;SafeGuarding 1</div>
          </div>
          <div className="qualifications__table__row">
            <div className="qualifications__cell">Peter McDonald</div>
            <div className="qualifications__cell">&bull;Garda Vetting<br/>&bull;SafeGuarding 1</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Qualifications;