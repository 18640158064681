import React from "react";
import CookieConsent from "react-cookie-consent";

function Footer() {
  return (
    <footer className="py-5 bg-dark">
    <div className="container">
      <p className="m-0 text-center text-white">Copyright &copy; New Ross Celtic 2020</p>
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={180}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
    </div>
    {/* /.container */}
    </footer>
  );
}

export default Footer;