import React, { Component } from 'react';

class NewsItems extends Component {
    render() {
        const news = this.props.news;
        const resultHtml = [];
        if (news != null) {
            for(let i=0; i<news.length; i++) {
                resultHtml.push(
                    <div className="card" key={i}>
                        <div className="card-header" id={"heading" + i}>
                            <h5 className="mb-0">
                                <button className="btn-news-item-header btn btn-link" data-toggle="collapse" data-target={"#collapse" + i} aria-expanded="true" aria-controls={"collapse" + i}>
                                    {news[i].shortDesc}
                                </button>
                            </h5>
                        </div>
                        <div id={"collapse" + i} className="collapse" aria-labelledby={"heading" + i} data-parent="#accordion">
                            <div className="card-body" dangerouslySetInnerHTML={{ __html: news[i].longDesc }} />
                        </div>
                    </div>
                );
            }
        }
        return (
            <div>{resultHtml}</div>
        )
    }
}
export default NewsItems;