import React from 'react';
import './Resources.css';
import crest from 'img/contact/crest.jpg';

function ClubEthosStatement() {

  return (
    <div className='container club-resources-container'>
      <div className="resources-crest">
        <div>
          <img src={crest} width="150" height="215" alt="New Ross Celtic Crest" />
        </div>
        <div>
          <strong>Grounds:</strong><br/>Butlersland, New Ross,<br/>Co. Wexford, Y34 YN56
        </div>
      </div>
      <hr/>
      <div>
        <h1>CLUB ETHOS STATEMENT</h1>
        <p>
          The work of New Ross Celtic AFC is based on the following principles that will guide the development of football in this club. Children and young people’s experience of football should be directed by what is best for the child. The stages of development and the ability of the child should guide the types of activity provided in the club. Adults require a basic understanding of the needs of young people, including physical, emotional and personal.
        </p>
        <h2>Integrity in Relationships:</h2>
        <p>
          Adults interacting with young people and each other should do so with integrity and respect. All adult actions in football should be guided by what is best for the child and in the context of quality, open working relationships. Verbal, physical, emotional or sexual abuse of any kind is unacceptable
        </p>
        <h2>Quality Atmosphere and Ethos</h2>
        <p>
          Football and related duties should be conducted in a safe, positive and encouraging atmosphere. A child-centred ethos will help to ensure that competition and specialisation are kept in their appropriate place and age groups. The onus is on adults to lead by example in creating an atmosphere that benefits the club and the club’s members.
        </p>
        <h2>Equality</h2>
        <p>
          All people should be treated in an equitable and fair manner regardless of age, ability, sex, religion, social and ethnic background or political persuasion. Children with a disability, where possible should be involved in sports activities in an integrated way, thus allowing them to participate to their potential alongside their peers.
        </p>
        <h2>Fair Play</h2>
        <p>
          Fair play is the guiding principle of Sport Ireland’s Code of Ethics and Good Practice for Children’s Sport. Fair Play is also a FIFA tagline that promotes the spirit of fair play and compassion in association football around the world. Sport Ireland state that “all children’s sport should be conducted in an atmosphere of fair play”. Ireland has contributed and is committed to the European Code of Sports Ethics, which defines fair play as: “much more than playing within the rules”. It incorporates the concepts of friendship, respect for others and always playing with the right spirit. Fair play is defined as a way of thinking, not just behaving. It incorporates issues concerned with the elimination of opportunities, excessive commercialisation and corruption. New Ross Celtic AFC adheres to the ethos of Fair play in both a Sport Ireland and FIFA context. (European Sports Charter and Code of Ethics, Council of Europe, 1993).
        </p>
        <h2>Competition</h2>
        <p>
          A balanced approach to competition can make a significant contribution to the development of young people, while at the same time providing fun, enjoyment and satisfaction. Coaches/managers should aim to put the welfare of the child first and competitive standards second. A child-centred approach based on the FAI’s Player Development Plan will be followed. Coaches and parents will need to know and understand the stages of the Player Development Plan
        </p>
      </div>
    </div>
  );
}

export default ClubEthosStatement;