import React, {useState, useRef, useEffect, useContext} from 'react';
import './RecordAttendance.css';
import AdminHeaderBanner from 'components/Admin/Common/AdminHeaderBanner/AdminHeaderBanner';
import { RossCelticContext } from 'ContextApi';
import Select from 'react-select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableFooter, TableHead } from '@mui/material';

let season = '';
let teamId = 0;
let nbrDays = 0;
let teamsOptions = [];
// TODO - Ideally load the seasons from Database and add the 30/60 days to the list too
let rangeOptions = [{ value: "2022-2023", label: "2022-2023"}, { value: "30", label: "Last 30 Days"}, { value: "60", label: "Last 60 Days"}];

function ViewAttendance() {

  const { recordAttendanceService } = useContext(RossCelticContext);
  const refFetchAttendanceFromApi = useRef(recordAttendanceService);
  const refFetchTeamsFromApi = useRef(recordAttendanceService.loadTeamsApi());
  const [seasonAttendanceRecords, setSeasonAttendanceRecords] = useState([]);
  const [selectedTeamValue, setSelectedTeamValue] = useState(0);
  const [selectedRangeValue, setSelectedRangeValue] = useState(0);
  const [headerValue, setHeaderValue] = useState("");
  const [attendanceDates, setAttendanceDates] = useState([]);
  const [playersAttendance, setPlayersAttendance] = useState([]);

  /**
     * Set the Team value selected in drop down.
     * @param {Dropdown element}} e 
     */
  const handleTeamValueChange = e => {
    // resetDisplayValues();
    setSelectedTeamValue(e.value);
  }

  /**
     * Set the attendance range value selected in drop down.
     * @param {Dropdown element}} e 
     */
  const handleRangeValueChange = e => {
    // resetDisplayValues();
    setSelectedRangeValue(e.value);
  }

  function createData( name, nbrTrainings, datesAttended) {
    return { name, nbrTrainings, datesAttended };
  }

  // controller space
  useEffect(onRender, []);

  function onRender() {
    //
    // THE FOLLOWING WILL BE LOADED EVERYTIME ANY ACTION IS CARRIED OUT ON PAGE.
    // MIGHT WANT TO LOOK TO SEE IF IT CAN ONLY BE DONE ONCE ON PAGE LOAD
    //

    // Load all Teams from Database
    refFetchTeamsFromApi.current
    .then((result) => {
      //console.log('Result is : ', result);
      if (result && result.data) {
        if (result.data.result && result.data.result === 'false') {
          //console.log('Result = ', result.data.result)
          alert("Please contact Administrator. " + result.data.message);
        } else {
          result.data.forEach(f => {
            teamsOptions.push({ value: f.teamId, label: f.name});
          });
        }
      }
    })
    .catch(err => {
        // TODO - deal with error
        console.error("Error Loading Teams for View Attendance : ", err);
        alert("Please contact Administrator as there was an issue loading teams for View Attendance.");
    })
  };

  /**
   * 
   * @returns Load Attendance Records based on Team and Range selected on screen
   */
  function onClickLoadAttendanceRecords() {
    // Clear out List of Dates array
    //setAttendanceDates([]);
    if (selectedTeamValue == null || selectedTeamValue === '' || selectedTeamValue === 0) {
      alert('Team is empty. Please select and try again');
      return;
    }
    else if (selectedRangeValue == null || selectedRangeValue === '' || selectedRangeValue === 0) {
      alert('Attendance Range is empty. Please select and try again');
      return;
    }
    else {
      console.log('selected Range Value is : ' + selectedRangeValue);
      if (selectedRangeValue != null && (selectedRangeValue === '30' || selectedRangeValue === '60')) {
        loadAttendanceRecordsForNbrDays(selectedTeamValue, selectedRangeValue);
      }
      else {
        loadFullSeasonAttendanceRecords(selectedTeamValue, selectedRangeValue);
      }
    }
  };

  function loadAttendanceRecordsForNbrDays(teamValue, rangeValue) {

    teamId = teamValue;
    nbrDays = rangeValue;
    const formRecord = {
      inputs: { 
        teamId: teamId,
        nbrDays: nbrDays,
      }
    };

    // Ensure season results is cleared out so table doesn't show
    setSeasonAttendanceRecords([]);

    // TODO - Maybe sort the dates in opposite direction
    // Load the dates that training was held on for the range
    console.log('Loading Attendance Records for nbr days ' + nbrDays + ' and team id ' + teamId);
    refFetchAttendanceFromApi.current.viewAttendanceDatesForDaysApi(formRecord)
    .then((result) => {
      console.log('Attendance Dates for nbrDays ' + nbrDays  + ' results : ', result);
      if (result != null && result.data != null && result.data.result != null && result.data.result === 'false') {
        // There was a problem loading the results
        console.log('ViewAttendance - Problem loading Attendance Results Records for nbr Days');
      } else {
        let temp = [];
        result.data.forEach((ex) => {
          //console.log('Found Attendance Date : ', ex.attendanceDates);
          const attendDate = ex.attendanceDates;
          console.log('About to push object to array', attendDate);
          temp.push(attendDate);
          //console.log('Pushed object to array ', temp);
        });
        console.log("Attendance Dates before setting are : ", attendanceDates);
        setAttendanceDates(temp);

        setHeaderValue("SESSIONS ATTENDED BY PLAYERS FOR " + getTeamName(selectedTeamValue) + " AND RANGE " + rangeValue + " days");
        console.log("Attendance Dates after setting are : ", attendanceDates);

        // This will return the list of trainings that players attended in a range.
        refFetchAttendanceFromApi.current.viewAttendanceRecordsByPlayersDaysApi(formRecord)
        .then((result1) => {
          console.log('Attendance Sessions for Players for nbrDays ' + nbrDays  + ' results : ', result1);
          if (result1 != null && result1.data != null && result1.data.result != null && result1.data.result === 'false') {
            // There was a problem loading the results
            console.log('ViewAttendance - Problem loading Attendance for Players Results Records for nbr Days');
          } else {
            let temp1 = [];
            result1.data.forEach((ex1) => {
              //console.log('About to push object to array', ex1);
              temp1.push(createData( ex1.teamMemberName, ex1.training_total, ex1.trainingDates));
              //console.log('Pushed object to array ', temp);
            });
            //console.log("Attendance Records for Players before setting are : ", playersAttendance);
            setPlayersAttendance(temp1);
          }
        })

      }
    })
  };

  function loadFullSeasonAttendanceRecords(teamValue, rangeValue) {
    season = selectedRangeValue;
    teamId = selectedTeamValue;
    const formRecord = {
      inputs: { 
        season: season,
        teamId: teamId
      }
    };

    // Ensure short range results is cleared out so table doesn't show
    setPlayersAttendance([]);

    // Load all team players training attendance for full season
    console.log('Loading Attendance Records for season ' + season + ' and team id ' + teamId);
    refFetchAttendanceFromApi.current.viewAttendanceReportApi(formRecord)
    .then((result) => {
      console.log('Attendance results : ', result);
      if (result != null && result.data != null && result.data.result != null && result.data.result === 'false') {
        // There was a problem loading the results
        console.log('ViewAttendance - Problem loading Attendance Results');
      } else {
        let temp = [];
        result.data.forEach((ex) => {
          //console.log('Found Attendance : ', ex.teamMemberName);
          const person = {
            'teamMemberName': ex.teamMemberName,
            'training_total': ex.training_total,
            'matches_total': ex.matches_total,
            'events_total': ex.events_total
          };
          //console.log('About to push object to array', person);
          temp.push(person);
          //console.log('Pushed object to array ', temp);
        });
        setSeasonAttendanceRecords(temp);

        setHeaderValue("SESSIONS ATTENDED BY PLAYERS FOR " + getTeamName(selectedTeamValue) + " AND RANGE " + selectedRangeValue);
        //console.log("Attendance Records are : ", seasonAttendanceRecords);
      }
    })
  };

  // Loop through map of teams to find name based on id
  function getTeamName(id) {
    return teamsOptions.filter(opt => opt.value === id)[0].label;
  }

  // Ensure first letter of each word is converted to uppercase
  function capitalizeName(name) {
    return name.replace(/\b(\w)/g, s => s.toUpperCase());
  }

  return (
    <div className='record-attendance'>
      <div className='selection-table'>
        <div className='selection-row'>
            <div className=''>
                Select Club Team :
            </div>
            <div className='selection-row-item-team'>
                <Select placeholder="Select Option"
                value={teamsOptions.find(obj => obj.value === selectedTeamValue)} // set selected value
                options={teamsOptions} // set list of the data
                onChange={handleTeamValueChange} // assign onChange function
                />
            </div>
        </div>
        <div className='selection-row'>
            <div className=''>
                Select Attendance Range :
            </div>
            <div className='selection-row-item-team'>
                <Select placeholder="Select Option"
                value={rangeOptions.find(obj => obj.value === selectedRangeValue)} // set selected value
                options={rangeOptions} // set list of the data
                onChange={handleRangeValueChange} // assign onChange function
                />
            </div>
        </div>
        <div className='selection-row'>
          <div className='selection-row-label'></div>
          <div className='selection-row-item'>
              <input type="button" className="selection-btn" onClick={() => onClickLoadAttendanceRecords()} value="Load Attendance Records" />
          </div>
        </div>
      </div>
      <AdminHeaderBanner text={headerValue} />
      <div className='session-attendance'>
        <div>
          <div className="attendance-table">
            { seasonAttendanceRecords.length < 1 && playersAttendance.length < 1 &&
              <div className="attendance__table__row">
                <div className="attendance__cell-single"><strong>No Attendance Records Found. Please refresh page or try again later</strong></div>
              </div>
            }
            { seasonAttendanceRecords.length > 0 &&
              <div className="attendance__table__row">
                <div className="attendance__cell attendance__cell--shaded">PLAYER NAME</div>
                <div className="attendance__cell attendance__cell--shaded">TRAINING SESSIONS ATTENDED</div>
                <div className="attendance__cell attendance__cell--shaded">MATCHES ATTENDED</div>
                <div className="attendance__cell attendance__cell--shaded">EVENTS ATTENDED</div>
              </div>
            }
              {seasonAttendanceRecords.map((teamMember, index) => (
                <div className="attendance__table__row" key={index}>
                  <div className="attendance__cell">
                    <span>{capitalizeName(teamMember.teamMemberName)}
                    {teamMember.teamMemberType && teamMember.teamMemberType === 5051 && <span>&nbsp;<strong>(Coach)</strong></span>}
                    </span>
                  </div>
                  <div className="attendance__cell">{teamMember.training_total}</div>
                  <div className="attendance__cell">{teamMember.matches_total}</div>
                  <div className="attendance__cell">{teamMember.events_total}</div>
                </div>
              ))}
          </div>
        </div>
        <hr />
      </div>
      { playersAttendance.length > 0 &&
      <TableContainer component={Paper}>
        <Table sx={{ width: "80%", margin: "20px", border: "none", borderCollapse: "separate", borderSpacing: "0px 4px" }} size="small" aria-label="attendance table" border="0">
          <TableHead>
            <TableRow>
              <TableCell key="head-1" style={{border:"none", backgroundColor: "black", color: "white", fontWeight: "bold"}}>Name</TableCell>
              <TableCell key="head-2" align='center'  style={{border:"none", backgroundColor: "black", color: "white", fontWeight: "bold"}}>Attended</TableCell>
              {attendanceDates.map((attendanceDate) => (
                <TableCell  key={attendanceDate} align="right" style={{border:"none", backgroundColor: "black", color: "white"}}>{attendanceDate}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {playersAttendance.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: "none" } }}
              >
                <TableCell key="body-1" width={"15%"} component="th" scope="row" style={{border:"none"}}>{capitalizeName(row.name)}</TableCell>
                <TableCell key="body-2" width={"15%"} component="th" scope="row" align='center' style={{border:"none"}}>{row.nbrTrainings}</TableCell>
                {attendanceDates.map((attendanceDate) => (
                  row.datesAttended.includes(attendanceDate)
                  ? <TableCell key={attendanceDate} sx={{backgroundColor:'green', color: 'black', border:"none"}} align="right"></TableCell>
                  : <TableCell key={attendanceDate} sx={{color: 'black', border:"none"}} align="right"></TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell key="footer-1" style={{border:"none"}}></TableCell>
              <TableCell key="footer-2" style={{border:"none"}}></TableCell>
              {attendanceDates.map((attendanceDate) => (
                <TableCell key={attendanceDate} style={{border:"none", fontWeight: "bold"}}>{attendanceDate}</TableCell>
              ))}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      }
    </div>
  );
}

export default ViewAttendance;