import React, {useState, useRef, useEffect, useContext} from 'react';
import './RecordAttendance.css';
import AdminHeaderBanner from 'components/Admin/Common/AdminHeaderBanner/AdminHeaderBanner';
import { RossCelticContext } from 'ContextApi';
import Select from 'react-select';
import DatePicker from 'react-date-picker';

let options = [];
let season = 'EMPTY';
let sessionTypes = [{ value: 'training', label: 'Training'},{ value: 'match', label: 'Match'},{ value: 'event', label: 'Event'}];

function RecordAttendance() {

  const { recordAttendanceService } = useContext(RossCelticContext);
  const { commonService } = useContext(RossCelticContext);
  const refFetchSeasonFromApi = useRef(commonService.loadSeasonApi());
  const refFetchTeamsFromApi = useRef(recordAttendanceService.loadTeamsApi());
  const refFetchAttendanceRecordsFromApi = useRef(recordAttendanceService);
  const refFetchAllAttendanceTeamMembersFromApi = useRef(recordAttendanceService);
  const refInsertSessionAttendanceRecord = useRef(recordAttendanceService);

  const [dateValue, onChangeDate] = useState(new Date());
  const [selectedTeamValue, setSelectedTeamValue] = useState(0);
  const [existingAttendanceRecords, setExistingAttendanceRecords] = useState([]);
  const [distinctMemberNames, setDistinctMemberNames] = useState([]);
  const [sessionLoaded, setSessionLoaded] = useState(false);
  const formattedDate = new Intl.DateTimeFormat('en-GB',{day:'2-digit', month:'2-digit', year:'numeric'}).format(dateValue);
  const [selectedSessionType, setSelectedSessionType] = useState('training');

  /**
   * Set the team value selected in drop down.
   * @param {Dropdown element}} e 
   */
  const handleTeamValueChange = e => {
    resetDisplayValues();
    setSelectedTeamValue(e.value);
  }

  /**
   * Set the date value selected in date picker.
   * @param {Dropdown element}} e 
   */
  const onChangeDateValue = e => {
    resetDisplayValues();
    if (e) {
      onChangeDate(e);
    } else {
      onChangeDate(null);
    }
  }

  // Reset Display values
  const resetDisplayValues = function() {
    setExistingAttendanceRecords([]);
    setDistinctMemberNames([]);
    setSessionLoaded(false);
  }

  // Set the Selected Session Type Value
  const handleSessionTypeValueChange = e => {
    setSelectedSessionType(e.value);
  }

  // controller space
  useEffect(onRender, []);

  function onRender() {
    //
    // THE FOLLOWING WILL BE LOADED EVERYTIME ANY ACTION IS CARRIED OUT ON PAGE.
    // MIGHT WANT TO LOOK TO SEE IF IT CAN ONLY BE DONE ONCE ON PAGE LOAD
    //
    // Load current season to use when storing records
    refFetchSeasonFromApi.current
    .then((result) => {
      console.log('Seasons results : ', result);
      if (result != null && result.data != null && result.data.length < 2
        && result.data[0].name != null) {
          console.log('Found Season : ', result.data[0].name);
          season = result.data[0].name;
      }
    })
    .then(result => {
      // Load List of Teams assuming season was loaded
      if (season === null || season === 'EMPTY') {
        alert("Please contact Administrator as season was not loaded.");
      }
      else {
        refFetchTeamsFromApi.current
        .then((result) => {
          //console.log('Result is : ', result);
          if (result && result.data) {
            if (result.data.result && result.data.result === 'false') {
              //console.log('Result = ', result.data.result)
              alert("Please contact Administrator. " + result.data.message);
            } else {
              result.data.forEach(f => {
                options.push({ value: f.teamId, label: f.name});
              });
            }
          }
        })
        .catch(err => {
            // TODO - deal with error
            console.error("Error Loading Teams : ", err);
            alert("Please contact Administrator as there was an issue loading teams.");
        })
      }})
    .catch(err => {
      // TODO - deal with error
      console.error("Error Loading Season : ", err);
      alert("Please contact Administrator as there was an issue loading season.");
    })
  };

  const onClickLoadSession = () => {
    let allTeamMembersNames = [];
    let existingTeamMembersNames = [];

    if (!selectedTeamValue || selectedTeamValue === 0) {
      alert('You must select a Team from list');
    }
    else if (!dateValue) {
      alert('You must select a Date from Calendar');
    }
    else {
      const formRecord = {
        inputs: { 
            attendanceDate: formattedDate,
            teamId: selectedTeamValue,
            season: season
        }
      };
      // Load list of Members already recorded as attending a session for a date
      // and team in case some have already been added for that date
      refFetchAttendanceRecordsFromApi.current.loadAttendanceRecordsApi(formRecord)
      .then((result) => {
        if (result && result.data) {
          if (result.data.result && result.data.result === 'false') {
            console.log('Result = ', result.data.result)
            alert("Please contact Administrator. " + result.data.message);
            return false;
          } else {
            //console.log("Existing Records Results", result.data);
            result.data.forEach((ex) => {
              existingTeamMembersNames.push(ex.teamMemberName);
            })
          }
        }
        //console.log("Existing Records Names : ", existingTeamMembersNames);
        setExistingAttendanceRecords(result.data);

        // Load all distinct member names who have been to any session for this team in a season
        refFetchAllAttendanceTeamMembersFromApi.current.loadAllAttendanceTeamMembersApi(formRecord)
        .then((result) => {
          if (result && result.data) {
            if (result.data.result && result.data.result === 'false') {
              console.log('Result = ', result.data.result)
              alert("Please contact Administrator. " + result.data.message);
              return false;
            } else {
              console.log('All Members Records Results : ', result.data);
              result.data.forEach((r) => {
                allTeamMembersNames.push(r.teamMemberName);
              });
              console.log('All Member Names : ', allTeamMembersNames);
            }
          }

          const filteredResulst = allTeamMembersNames.filter(f => !existingTeamMembersNames.includes(f));
          setDistinctMemberNames(filteredResulst);
        })
        .catch(err => {
          // TODO - deal with error
          alert("There was a problem loading existing members for session. Please try again later or contact Administrator");
        });

        // Set flag to indicate that session details have been loaded
        setSessionLoaded(true);
      })
      .catch(err => {
          // TODO - deal with error
          alert("There was a problem loading records for session. Please try again later or contact Administrator");
      });
    }
  }

  /**
   * Add an Attendance Record for an existing member 
   * @param {checkbox element}} memberType 
   * @param {String} memberName 
   */
  function onClickAddToSession(memberName, memberType) {
    addAttendanceRecord(memberType, memberName);
    resetCheckBoxes();
  }

  /**
   * Add an Attendance Record for a new member that doesn't exist in Database
   * @param {checkbox element}} memberType 
   * @param {String} memberName 
   */
  function onClickAddNewMemberToSession(memberName, memberType) {
    // Ensure a New member name has been entered
    if (memberName && (!memberName.value || !memberName.value.length > 0)) {
      alert("Please enter a Member Name in New Member input field and try again.");
    } else if (memberName && memberName.value.length > 0) {
      addAttendanceRecord(memberType, memberName.value);
      // Ensure input box is cleared out afterwards.
      document.getElementById('newMemberName').value = '';
      resetCheckBoxes();
    } else {
      alert("There was a problem adding attendance Record for new Member. Please contact Administrator.");
    }
  }

  /**
   * Add an Attendance record to Database for a member on a date
   * @param {checkbox element}} memberType 
   * @param {String} memberName 
   */
  function addAttendanceRecord(memberType, memberName) {

    // Check to ensure Session Type has been selected
    console.log('session type is : ', selectedSessionType);

    if (selectedSessionType == null || selectedSessionType === '') {
      alert('Session Type is empty. Please select and try again');
      return;
    }
    else {
      let memberTypeValue = 5052; // Indicated it's a player
      if (memberType && memberType.checked) {
        memberTypeValue = 5051; // Indicated it's a coach/manager
      }

      // Trim any spaces from start and end of Name
      memberName = memberName.trim();

      const insertRecord = {
        inputs: {
          attendanceDate: formattedDate,
          teamId: selectedTeamValue,
          memberName: memberName.toLowerCase(),
          memberType: memberTypeValue,
          season: season,
          sessionType: selectedSessionType
        }
      };
      // Save member to session attendance table and add to existing array and remove from new members array
      refInsertSessionAttendanceRecord.current.addSessionAttendanceRecord(insertRecord)
        .then((result) => {

          // TODO - When saving a player record then show spinner to disable screen in case they click add for another person
          if (result && result.data && result.data.result && result.data.result === 'false') {
            alert("**** ERROR Adding person " + memberName + ". Please try again or contact Administrator.");
          }
          else {
            // Reload All records from Database to refresh screen
            onClickLoadSession();
            alert("Added Attendance Record for " + memberName);
          }
        })
        .catch(err => {
          // TODO - deal with error
          alert("There was a problem Adding attendance record for " + memberName + ". Please try again later or contact Administrator");
        });
    }
  }

  // Ensure first letter of each word is converted to uppercase
  function capitalizeName(name) {
    return name.replace(/\b(\w)/g, s => s.toUpperCase());
  }

  // Make sure all checkboxes are unchecked
  function resetCheckBoxes() {
    Array.from(document.querySelectorAll('input[type=checkbox]')).forEach(el => el.checked = false);
  }

  return (
    <div className='record-attendance'>
      <AdminHeaderBanner text="Record Session Attendance" />
      <div className='selection-table'>
        <div className='selection-row'>
          <div className='selection-row-label'>
            Select Team :
          </div>
          <div className='selection-row-item selection-row-item-team'>
            <Select placeholder="Select Option"
              value={options.find(obj => obj.value === selectedTeamValue)} // set selected value
              options={options} // set list of the data
              onChange={handleTeamValueChange} // assign onChange function
            />
          </div>
        </div>
        <div className='selection-row'>
          <div className='selection-row-label'>
            Select Date :
          </div>
          <div className='selection-row-item'>
            <DatePicker onChange={onChangeDateValue} value={dateValue} dateFormat="DD/MM/YYYY" />
          </div>
        </div>
        <div className='selection-row'>
          <div className='selection-row-label'></div>
          <div className='selection-row-item'>
            <button className="selection-row-item-btn" onClick={onClickLoadSession}>Load Session</button>
          </div>
        </div>
      </div>
      <div className='session-attendance'>
        {existingAttendanceRecords.length > 0 &&
          <div className="session-attendance__hdr">PEOPLE ALREADY RECORDED IN SESSION FOR {formattedDate}</div>
        }
        <div className="session-attendance__members">
          <div className="session-attendance__members__member__name">
            {existingAttendanceRecords.map((existingMember, index) => (
              <span key={index}>{capitalizeName(existingMember.teamMemberName)}
                {existingMember.teamMemberType && existingMember.teamMemberType === 5051 &&
                  <span className="session-attendance__members__member__type"> (Coach)</span>
                }({capitalizeName(existingMember.sessionType)}) / 
              </span>
            ))}
          </div>
        </div>
        <hr />
        {distinctMemberNames.length > 0 &&
          <div className="session-attendance__hdr">SELECT SESSION TYPE AND MEMBERS BELOW FOR {formattedDate}</div>
        }
        {/* Show Session Type Checkboxes */}
        {sessionLoaded &&
          <div className='selection-row'>
            <div className='selection-row-label'>
              <strong>Select Session Type :</strong>
            </div>
            <div className='selection-row-item selection-row-item-team'>
              <Select id='sessionTypeId'
                value={sessionTypes.find(obj => obj.value === selectedSessionType)} // set selected value
                options={sessionTypes} // set list of the data
                onChange={handleSessionTypeValueChange}
              />
            </div>
          </div>
        }
        {/* Show Members already added for this session */}
        <div className="session-members">
          {distinctMemberNames.map((memberName, index)=> (
            <div className="session-members__member" key={index}>
              <div className="session-members__member__name">{capitalizeName(memberName)}</div>
              <div className="session-members__member__type">
                &nbsp;&nbsp;<input type="checkbox" id={'existingMemberType'+index} />&nbsp;(Coach)&nbsp;&nbsp;
              </div>
              <div>
                <input type="button" className="session-btn" onClick={() => onClickAddToSession(memberName, document.getElementById('existingMemberType'+index))} value="Add to Session" />
              </div>
            </div>
          ))}
        </div>
        {sessionLoaded &&
          <div className="new-member">
            <div>Add person to this session if they don't exist above</div>
            <div className="new-member-record">
              <input type="text" id="newMemberName" className="new-member-input-box"></input>
              &nbsp;&nbsp;<input type="checkbox" id={'newMemberType'} />&nbsp;(Coach)&nbsp;&nbsp;
              <input type="button" className="session-btn" onClick={() => onClickAddNewMemberToSession(document.getElementById('newMemberName'), document.getElementById('newMemberType'))} value="Add to Session" />
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default RecordAttendance;