import {useState, useEffect} from 'react';
import axios from 'axios';
// Authentication Service from UserFront
import Userfront from "@userfront/react";

function CommonModel() {

  async function loadSeasonApi() {
    let result;
    await axios.get('/api/loadSeason', {
      headers: {
        "Authorization": `Bearer ${Userfront.accessToken()}`,
      }
    })
    .then((response) => {
      //console.log('Response is : ', response);
      result = response;
    })
    .catch((error) => {
      console.log("Common Model - Exception caught loading Season ", error);
      result = { "data" : {
        "result" : "false",
        "message" : "There was an issue loading Season"
      }};
    });
    return result;
  }

  async function loadClubSectionsApi() {
    let result;
    await axios.get('/api/loadClubSections', {
      headers: {
        "Authorization": `Bearer ${Userfront.accessToken()}`,
      }
    })
    .then((response) => {
      //console.log('loadClubSectionsApi Response is : ', response);
      result = response;
    })
    .catch((error) => {
      console.log("Common Model - Exception caught loading Club Sections ", error);
      result = { "data" : {
        "result" : "false",
        "message" : "There was an issue loading Club Sections"
      }};
    });
    return result;
  }

  // Api space
  return {
    loadSeasonApi,
    loadClubSectionsApi
  }
}

export default CommonModel;