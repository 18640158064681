import React from 'react';
import './AdminHeaderBanner.css';
// Authentication Service from UserFront
import Userfront from "@userfront/react";

export default function AdminHeaderBanner(props) {

  // If the user is logged in, show the dashboard
  const userData = JSON.stringify(Userfront.user, null, 2);

  return (
      <div className='admin-banner-hdr'>
        <div className='admin-banner-hdr-user'><pre>{userData}</pre></div>
        <div className='admin-banner-hdr-text'>{props.text}</div>
        <div className='admin-banner-hdr-btn'>
          <button onClick={Userfront.logout}>Logout</button><br/>
          <a href='/dashboard'>Main Menu</a></div>
      </div>
  );
}