import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css"
/** JSCONFIG.JSON file has baseUrl specified so don't need to search back up directory */
import img1 from 'img/gallery/old_photos/img1.jpg';
import img1_tn from 'img/gallery/old_photos/img1_tn.jpg';
import img2 from 'img/gallery/old_photos/img2.jpg';
import img2_tn from 'img/gallery/old_photos/img2_tn.jpg';
import img3 from 'img/gallery/old_photos/img3.jpg';
import img3_tn from 'img/gallery/old_photos/img3_tn.jpg';
import img4 from 'img/gallery/old_photos/img4.jpg';
import img4_tn from 'img/gallery/old_photos/img4_tn.jpg';
import img5 from 'img/gallery/old_photos/img5.jpg';
import img5_tn from 'img/gallery/old_photos/img5_tn.jpg';
import img6 from 'img/gallery/old_photos/img6.jpg';
import img6_tn from 'img/gallery/old_photos/img6_tn.jpg';
import img7 from 'img/gallery/old_photos/img7.jpg';
import img7_tn from 'img/gallery/old_photos/img7_tn.jpg';
import img8 from 'img/gallery/old_photos/img8.jpg';
import img8_tn from 'img/gallery/old_photos/img8_tn.jpg';
import img9 from 'img/gallery/old_photos/img9.jpg';
import img9_tn from 'img/gallery/old_photos/img9_tn.jpg';
import img10 from 'img/gallery/old_photos/img10.jpg';
import img10_tn from 'img/gallery/old_photos/img10_tn.jpg';
import img11 from 'img/gallery/old_photos/img11.jpg';
import img11_tn from 'img/gallery/old_photos/img11_tn.jpg';
import img12 from 'img/gallery/old_photos/img12.jpg';
import img12_tn from 'img/gallery/old_photos/img12_tn.jpg';
import img13 from 'img/gallery/old_photos/img13.jpg';
import img13_tn from 'img/gallery/old_photos/img13_tn.jpg';
import img14 from 'img/gallery/old_photos/img14.jpg';
import img14_tn from 'img/gallery/old_photos/img14_tn.jpg';
import img15 from 'img/gallery/old_photos/img15.jpg';
import img15_tn from 'img/gallery/old_photos/img15_tn.jpg';
import img16 from 'img/gallery/old_photos/img16.jpg';
import img16_tn from 'img/gallery/old_photos/img16_tn.jpg';
import img17 from 'img/gallery/old_photos/img17.jpg';
import img17_tn from 'img/gallery/old_photos/img17_tn.jpg';
import img18 from 'img/gallery/old_photos/img18.jpg';
import img18_tn from 'img/gallery/old_photos/img18_tn.jpg';
import img19 from 'img/gallery/old_photos/img19.jpg';
import img19_tn from 'img/gallery/old_photos/img19_tn.jpg';
import img20 from 'img/gallery/old_photos/img20.jpg';
import img20_tn from 'img/gallery/old_photos/img20_tn.jpg';

let imgs = [[img1,img1_tn], [img2,img2_tn], [img3,img3_tn], [img4,img4_tn], [img5,img5_tn],
            [img6,img6_tn], [img7,img7_tn], [img8,img8_tn], [img9,img9_tn], [img10,img10_tn],
            [img11,img11_tn], [img12,img12_tn], [img13,img13_tn], [img14,img14_tn], [img15,img15_tn],
            [img16,img16_tn], [img17,img17_tn], [img18,img18_tn], [img19,img19_tn], [img20,img20_tn]];
let images = [];
imgs.forEach((item, index) => {
  //console.log(item.toString());
  images.push({ original: item[0].toString(), thumbnail: item[1].toString() });
});

export default class GalleryOne extends Component {

  render() {
    
    return (
      <div>
        <span>** All Photos on this page were supplied to New Ross Celtic by Mick Breen and we would like to thank him for allowing us to use these. **</span>
        <div>
          <ImageGallery items={images} />;
        </div>
      </div>
    );
  }
}