import React from "react";
import './Honours.css';
import crest from 'img/contact/crest.jpg';
import honoursImg from 'img/honours/honours.png';
import manImg from 'img/honours/male-silhouette.jpg';
import womanImg from 'img/honours/woman-silhouette.JPG';
import boyImg from 'img/honours/boy-silhouette.JPG';
import girlImg from 'img/honours/girl-silhouette.JPG';

function Honours() {
  return (
    <div className="honours">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-6">
            <img className="img-fluid rounded mb-4 mb-lg-0"
                 src={crest} alt="New Ross Celtic Crest"
            />
          </div>
          <div className="col-lg-6">
            <img className="img-fluid rounded mb-4 mb-lg-0"
                 src={honoursImg} alt="Honours"
            />
          </div>
        </div>
        <div className="honours-heading">MEN<img className="img-fluid rounded mb-4 mb-lg-0" src={manImg} alt="Man Silhouette" /></div>
        {/* Add an image of men here */}
        <div className="honours-table">
          <div className="honours__table__row">
            <div className="honours-year honours--shaded">YEAR</div>
            <div className="honours-honour honours--shaded">HONOURS</div>
          </div>
          <div className="honours__table__row"><div className="honours-year">1968</div><div className="honours-honour">Wexford Cup winners</div></div>
          <div className="honours__table__row"><div className="honours-year">1975</div><div className="honours-honour">Wexford Shield Winners</div></div>
          <div className="honours__table__row"><div className="honours-year">1977-78</div><div className="honours-honour">Wexford Cup Winners, Division 1 Winners, and Reserve League winners</div></div>
          <div className="honours__table__row"><div className="honours-year">1978-79</div><div className="honours-honour">Division 1 and Reserve League winners</div></div>
          <div className="honours__table__row"><div className="honours-year">1979-80</div><div className="honours-honour">Wexford Cup Runners-Up, Division 1 winners, Reserve League Runners-Up</div></div>
          <div className="honours__table__row"><div className="honours-year">1980-81</div><div className="honours-honour">Wexford Cup winners</div></div>
          <div className="honours__table__row"><div className="honours-year">1981-82</div><div className="honours-honour">Division 1 winners</div></div>
          <div className="honours__table__row"><div className="honours-year">1982-83</div><div className="honours-honour">Leinster Junior Cup winners, Premier League winners, Reserve League winners</div></div>
          <div className="honours__table__row"><div className="honours-year">1983-84</div><div className="honours-honour">Wexford Cup winners, Premier League winners, and Reserve League winners. FAI Junior Cup Semi-finalists</div></div>
          <div className="honours__table__row"><div className="honours-year">1984-85</div><div className="honours-honour">Wexford Cup winners, Premier League winners, Reserve Runners-Up</div></div>
          <div className="honours__table__row"><div className="honours-year">1986-87</div><div className="honours-honour">Wexford Cup Runners-Up, Premier League Runners-Up, U-18 Cup winners</div></div>
          <div className="honours__table__row"><div className="honours-year">1988-89</div><div className="honours-honour">Leinster Jun. Cup semi-finalists, Premier League winners</div></div>
          <div className="honours__table__row"><div className="honours-year">1989-90</div><div className="honours-honour">Leinster Junior Cup Quarter-Finalists, Premier League winners</div></div>
          <div className="honours__table__row"><div className="honours-year">1990-91</div><div className="honours-honour">Premier League winners, Reserve League runners up</div></div>
          <div className="honours__table__row"><div className="honours-year">1991-92</div><div className="honours-honour">Wexford Cup Runners-Up, Premier League Runners-Up</div></div>
          <div className="honours__table__row"><div className="honours-year">1992-93</div><div className="honours-honour">Premier League winners</div></div>
          <div className="honours__table__row"><div className="honours-year">1993-94</div><div className="honours-honour">FAI Junior Cup Runners-Up, FAI Junior Cup Area Winners, Wexford Cup winners, Reserve League winners</div></div>
          <div className="honours__table__row"><div className="honours-year">1994-95</div><div className="honours-honour">Premier League Runners-Up, Reserve League Winners, U18 Div.1 Runners-Up</div></div>
          <div className="honours__table__row"><div className="honours-year">1995-96</div><div className="honours-honour">FAI Junior Cup Area Runners-Up, Wexford Cup and Premier League Runners-Up, Reserve League Winners</div></div>
          <div className="honours__table__row"><div className="honours-year">1996-97</div><div className="honours-honour">U18 Division1 Winners</div></div>
          <div className="honours__table__row"><div className="honours-year">1998-99</div><div className="honours-honour">Wexford Cup Runners-Up</div></div>
          <div className="honours__table__row"><div className="honours-year">1999-2000</div><div className="honours-honour">Premier Runners-Up, Reserve, Division 5A League Winners (C Team) ,Billy Brown Cup Runners-Up</div></div>
          <div className="honours__table__row"><div className="honours-year">2000-01</div><div className="honours-honour">Premier League Winners, Reserve League Winners, Wexford Cup Runners-Up, 4th Division Runners-Up, U18 Youth Cup Winners</div></div>
          <div className="honours__table__row"><div className="honours-year">2001-02</div><div className="honours-honour">Wexford Cup Runners-Up</div></div>
          <div className="honours__table__row"><div className="honours-year">2006-07</div><div className="honours-honour">Premier League Winners, Wexford Cup Winners</div></div>
          <div className="honours__table__row"><div className="honours-year">2007-08</div><div className="honours-honour">Premier League Winners</div></div>
          <div className="honours__table__row"><div className="honours-year">2008-09</div><div className="honours-honour">Premier League Winners, Wexford Cup Winners</div></div>
          <div className="honours__table__row"><div className="honours-year">2009-10</div><div className="honours-honour">Premier League Winners</div></div>
          <div className="honours__table__row"><div className="honours-year">2010-11</div><div className="honours-honour">FAI Junior Cup Semi-Finalists, Premier League Runners-Up</div></div>
          <div className="honours__table__row"><div className="honours-year">2011-12</div><div className="honours-honour">U18 Youth Cup Winners, Wexford Cup Runners-Up</div></div>
          <div className="honours__table__row"><div className="honours-year">2012-13</div><div className="honours-honour">Div 5 League Winners, Gwyn Jones Cup Runners-Up, U18 Youth Premier League Runners-Up</div></div>
          <div className="honours__table__row"><div className="honours-year">2013-14</div><div className="honours-honour">Div 4 League Winners</div></div>
          <div className="honours__table__row"><div className="honours-year">2021-22</div><div className="honours-honour">U18 Youth Div 2 League Winners</div></div>
          <div className="honours__table__row"><div className="honours-year">2022-23</div><div className="honours-honour">Div 2 League Winners, Neil O&apos;Sullivan Cup Winners, Div 5 League Runners-Up, U19 League Runner-Up</div></div>
        </div>
        <div className="honours-heading">WOMEN<img className="img-fluid rounded mb-4 mb-lg-0" src={womanImg} alt="Woman Silhouette" /></div>
        {/* Add an image of women here */}
        <div className="honours-table">
          <div className="honours__table__row">
            <div className="honours-year honours--shaded">YEAR</div>
            <div className="honours-honour honours--shaded">HONOURS</div>
          </div>
          <div className="honours__table__row"><div className="honours-year">2007-08</div><div className="honours-honour">Etchingham Cup Winners</div></div>
        </div>
        <div className="honours-heading">SCHOOLBOYS<img className="img-fluid rounded mb-4 mb-lg-0" src={boyImg} alt="Boy Silhouette" /></div>
        {/* Add an image of School Boys here */}
        <div className="honours-table">
          <div className="honours__table__row">
            <div className="honours-year honours--shaded">TEAM</div>
            <div className="honours-honour honours--shaded">HONOURS/YEAR</div>
          </div>
          <div className="honours__table__row"><div className="honours-year">Under 10</div><div className="honours-honour"><strong>Mini World Cup</strong> - 1978, 1979, 1980, 1999, 2005, 2008, 2009<br/><strong>Cup</strong> - 1990, 2007<br/><strong>League Div1 Southwest</strong> - 1998, 1999, 2000, 2001, 2006<br/><strong>League Div2 Southwest</strong> - 2001, 2005, 2006<br/><strong>League Div3 South</strong> - 2007, 2008</div></div>
          <div className="honours__table__row"><div className="honours-year">Under 11</div><div className="honours-honour"><strong>Danone Cup National Finalists</strong> - 2008<br/><strong>League Div 1A</strong> - 2013</div></div>
          <div className="honours__table__row"><div className="honours-year">Under 12</div><div className="honours-honour"><strong>Cup</strong> - 2009<br/><strong>League Div 1</strong> - 2009<br/><strong>League Div 1 Runners Up</strong> - 2017<br/><strong>League Div 2</strong> - 2013</div></div>
          <div className="honours__table__row"><div className="honours-year">Under 14</div><div className="honours-honour"><strong>Cup</strong> - 1987, 1993, 1996, 2010, 2013<br/><strong>League Div 1</strong> - 1996, 2010<br/><strong>League Div 2</strong> - 2007, 2017, 2022</div></div>
          <div className="honours__table__row"><div className="honours-year">Under 15</div><div className="honours-honour"><strong>League Div 2</strong> - 2017</div></div>
          <div className="honours__table__row"><div className="honours-year">Under 16</div><div className="honours-honour"><strong>Cup</strong> - 1999, 2012<br/><strong>League Div 1</strong> - 1989, 1990, 2012<br/><strong>League Div 3</strong> - 2005, 2017</div></div>
        </div>
        <div className="honours-heading">SCHOOLGIRLS<img className="img-fluid rounded mb-4 mb-lg-0" src={girlImg} alt="Girl Silhouette" /></div>
        {/* Add an image of School Boys here */}
        <div className="honours-table">
          <div className="honours__table__row">
            <div className="honours-year honours--shaded">TEAM</div>
            <div className="honours-honour honours--shaded">HONOURS/YEAR</div>
          </div>
          <div className="honours__table__row"><div className="honours-year">Under 10</div><div className="honours-honour"><strong>South West Division Winners</strong> - 2015<br/><strong>League Division 1 Runners-Up</strong> - 2015</div></div>
          <div className="honours__table__row"><div className="honours-year">Under 12</div><div className="honours-honour"><strong>League Division 2A Winners</strong> - 2017<br/><strong>League Div 3 Winners</strong> - 2015</div></div>
        </div>
      </div>
    </div>
  );
}

export default Honours;