import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css"

/** JSCONFIG.JSON file has baseUrl specified so don't need to search back up directory */
import img21 from 'img/gallery/old_photos/img21.jpg';
import img21_tn from 'img/gallery/old_photos/img21_tn.jpg';
import img22 from 'img/gallery/old_photos/img22.jpg';
import img22_tn from 'img/gallery/old_photos/img22_tn.jpg';
import img23 from 'img/gallery/old_photos/img23.jpg';
import img23_tn from 'img/gallery/old_photos/img23_tn.jpg';
import img24 from 'img/gallery/old_photos/img24.jpg';
import img24_tn from 'img/gallery/old_photos/img24_tn.jpg';
import img25 from 'img/gallery/old_photos/img25.jpg';
import img25_tn from 'img/gallery/old_photos/img25_tn.jpg';
import img26 from 'img/gallery/old_photos/img26.jpg';
import img26_tn from 'img/gallery/old_photos/img26_tn.jpg';
import img27 from 'img/gallery/old_photos/img27.jpg';
import img27_tn from 'img/gallery/old_photos/img27_tn.jpg';
import img28 from 'img/gallery/old_photos/img28.jpg';
import img28_tn from 'img/gallery/old_photos/img28_tn.jpg';
import img29 from 'img/gallery/old_photos/img29.jpg';
import img29_tn from 'img/gallery/old_photos/img29_tn.jpg';
import img30 from 'img/gallery/old_photos/img30.jpg';
import img30_tn from 'img/gallery/old_photos/img30_tn.jpg';
import img31 from 'img/gallery/old_photos/img31.jpg';
import img31_tn from 'img/gallery/old_photos/img31_tn.jpg';
import img32 from 'img/gallery/old_photos/img32.jpg';
import img32_tn from 'img/gallery/old_photos/img32_tn.jpg';
import img33 from 'img/gallery/old_photos/img33.jpg';
import img33_tn from 'img/gallery/old_photos/img33_tn.jpg';
import img34 from 'img/gallery/old_photos/img34.jpg';
import img34_tn from 'img/gallery/old_photos/img34_tn.jpg';
import img35 from 'img/gallery/old_photos/img35.jpg';
import img35_tn from 'img/gallery/old_photos/img35_tn.jpg';
import img36 from 'img/gallery/old_photos/img36.jpg';
import img36_tn from 'img/gallery/old_photos/img36_tn.jpg';
import img37 from 'img/gallery/old_photos/img37.jpg';
import img37_tn from 'img/gallery/old_photos/img37_tn.jpg';
import img38 from 'img/gallery/old_photos/img38.jpg';
import img38_tn from 'img/gallery/old_photos/img38_tn.jpg';
import img39 from 'img/gallery/old_photos/img39.jpg';
import img39_tn from 'img/gallery/old_photos/img39_tn.jpg';
import img40 from 'img/gallery/old_photos/img40.jpg';
import img40_tn from 'img/gallery/old_photos/img40_tn.jpg';

let imgs = [[img21,img21_tn], [img22,img22_tn], [img23,img23_tn], [img24,img24_tn], [img25,img25_tn],
            [img26,img26_tn], [img27,img27_tn], [img28,img28_tn], [img29,img29_tn], [img30,img30_tn],
            [img31,img31_tn], [img32,img32_tn], [img33,img33_tn], [img34,img34_tn], [img35,img35_tn],
            [img36,img36_tn], [img37,img37_tn], [img38,img38_tn], [img39,img39_tn], [img40,img40_tn]];
let images = [];
imgs.forEach((item, index) => {
  images.push({ original: item[0].toString(), thumbnail: item[1].toString() });
});

export default class GalleryTwo extends Component {

  render() {
    
    return (
      <div>
        <span>** All Photos on this page were supplied to New Ross Celtic by Mick Breen and we would like to thank him for allowing us to use these. **</span>
        <div>
          <ImageGallery items={images} />;
        </div>
      </div>
    );
  }
}