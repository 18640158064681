import React from 'react';
import './Resources.css';
import crest from 'img/contact/crest.jpg';

function CodeOfConductPlayers() {

  return (
    <div className='container club-resources-container'>
      <div className="resources-crest">
        <div>
          <img src={crest} width="150" height="215" alt="New Ross Celtic Crest" />
        </div>
        <div>
          <strong>Grounds:</strong><br/>Butlersland, New Ross,<br/>Co. Wexford, Y34 YN56
        </div>
      </div>
      <hr/>
      <div>
        <h1>PLAYERS CODE OF CONTACT</h1>
        <p className="club-social-media-content">
          As players are constantly in the glare of public attention and are widely recognised as the most important people in the sport, there is a considerable onus upon them to speak and act in a manner which promotes all that is good about the game and the club. As a player, I will:
          <ul>
            <li>Make every honest effort to develop my sporting ability, to include fitness, skill, technique and tactical ability. • Strive to set a positive example for younger players and supporters.</li>
            <li>Safeguard the physical fitness of opponents, avoid violence and rough play, and where possible help injured opponents.</li>
            <li>Give maximum effort and strive for the best possible performance during each game.</li>
            <li>Avoid all forms of gamesmanship, and time wasting.</li>
            <li>Refrain from using inappropriate language particularly to referees/officials and other players.</li>
            <li>Make every effort consistent with fair play and the laws of the game to help my team win.</li>
            <li>Abide by the laws, rules of the game and of any given competition, both in fact and in spirit.</li>
            <li>Accept victory and defeat with equanimity.</li>
            <li>Resist any temptation to consume or otherwise imbibe any banned substances.</li>
            <li>Treat opponents with respect at all times.</li>
            <li>Demonstrate due respect towards match officials.</li>
            <li>Accept the decisions of the match officials without protest.</li>
            <li>Avoid words or actions which may mislead a match official.</li>
            <li>Abide by the lawful instructions and directions of mentors and team officials.</li>
            <li>Act towards the mentors and officials of the opposing side in a respectful manner.</li>
            <li>Demonstrate due regard for the interest of supporters.</li>
            <li>Not act or speak so as to incite or encourage supporters to act in a way that may endanger the safety of anyone attending or participating in the game.</li>
            <li>Not either directly or indirectly place a bet on the outcome of any game in which I am involved.</li>
            <li>Have regard to the best interests of the game when publicly expressing an opinion on the game or on others involved in the game.</li>
            <li>In making public comments on a particular fixture or on the game in general, I will be mindful of upholding and promoting the good name of the game and others involved in it in the wider community.</li>
          </ul>
        </p>
      </div>
    </div>
  );
}

export default CodeOfConductPlayers;