import React from 'react';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';
import { withRouter } from "react-router-dom";

function Navigation(props) {

  // CHECK PATHNAME AND SET ACTIVE CLASS ON MENU ITEM - SO sub items will select parent item
  let pathname = "/";
  let clubInfoClassName = "";
  if (props && props.location && props.location.pathname) {
    pathname = props.location.pathname;
    // Alter Path Name for sub links that don't show on Top Navigation Menu Bar
    if (pathname === '/galleryOne' || pathname === '/galleryTwo' || pathname === '/galleryThree') {
      pathname = "/gallery";
    }
    // Highlight Club Info Top Navigation Menu Bar link of any of sub links are selected
    if (pathname === '/qualifications' || pathname === '/clubHonours' || pathname === '/clubEthosStatement'
        || pathname === '/socialMediaPolicy' || pathname === '/codeOfConductCoaches' || pathname === '/codeOfConductPlayers'
        || pathname === '/codeOfConductParents') {
      clubInfoClassName = "active";
    }
  }

  return (
    <div className="navigation">
      <Navbar bg="dark" className="navbar-dark" expand="lg">
        <div className="container">
          <Navbar.Brand href="/">New Ross Celtic</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/" className={` ${ pathname === "/" ? "active" : "" }`}>Home</Nav.Link>
              <Nav.Link href="/shop" className={` ${ pathname === "/shop" ? "active" : "" }`}>Shop</Nav.Link>
              <Nav.Link href="/gallery" className={` ${ pathname === "/gallery" ? "active" : "" }`}>Gallery</Nav.Link>
              <Nav.Link href="/contact" className={` ${ pathname === "/contact" ? "active" : "" }`}>Contact</Nav.Link>
              <NavDropdown title="Club Info" id="basic-nav-dropdown" className={`${clubInfoClassName}`}>
                <NavDropdown.Item href="/clubHonours" className={`${ pathname === "/clubHonours" ? "active" : "" }`}>Club Honours</NavDropdown.Item>
                <NavDropdown.Item href="/qualifications" className={`${ pathname === "/qualifications" ? "active" : "" }`}>Coaching Qualifications</NavDropdown.Item>
                <NavDropdown.Item href="/clubEthosStatement" className={`${pathname === "/clubEthosStatement" ? "active" : "" }`}>Club Ethos Statement</NavDropdown.Item>
                <NavDropdown.Item href="/socialMediaPolicy" className={`${pathname === "/socialMediaPolicy" ? "active" : "" }`}>Club Social Media Policy</NavDropdown.Item>
                <NavDropdown.Item href="/codeOfConductCoaches" className={`${pathname === "/codeOfConductCoaches" ? "active" : "" }`}>Coaches Code Of Conduct</NavDropdown.Item>
                <NavDropdown.Item href="/codeOfConductPlayers" className={`${pathname === "/codeOfConductPlayers" ? "active" : "" }`}>Players Code Of Conduct</NavDropdown.Item>
                <NavDropdown.Item href="/codeOfConductParents" className={`${pathname === "/codeOfConductParents" ? "active" : "" }`}>Parents Code Of Conduct</NavDropdown.Item>
                {/*<NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>*/}
              </NavDropdown>
            </Nav>
            {/*<Form inline>
              <FormControl type="text" placeholder="Search" className="mr-sm-2" />
              <Button variant="outline-success">Search</Button>
            </Form>*/}
          </Navbar.Collapse>
        </div>
      </Navbar>
    </div>
  );
}

export default withRouter(Navigation);