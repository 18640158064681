import React, { Component } from 'react';
/** JSCONFIG.JSON file has baseUrl specified so don't need to search back up directory */
import sponsor1 from 'img/sm_windows.JPG';
import sponsor2 from 'img/paddy_breens.png';
import sponsor3 from 'img/20x20.png';

class SponsorPanels extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-lg-4 col-sm-6 portfolio-item">
                    <div className="card h-100">
                        <a href="https://smsolutions.ie/" target="_blank" rel="noopener noreferrer"><img className="card-img-top" src={sponsor1} alt="SM Solutions windows and Doors"/></a>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 portfolio-item">
                    <div className="card h-100">
                        <a href="https://www.experthardware.ie/" target="_blank" rel="noopener noreferrer"><img className="card-img-top" src={sponsor2} alt="Paddy Breen's Hardware"/></a>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 portfolio-item">
                    <div className="card h-100">
                    <a href="https://20x20.ie/" target="_blank" rel="noopener noreferrer"><img className="card-img-top" src={sponsor3} alt="20x20 Women in Sport"/></a>
                    </div>
                </div>
                {/*
                <div className="col-lg-4 col-sm-6 portfolio-item">
                    <div className="card h-100">
                        <a href="#"><img className="card-img-top" src="http://placehold.it/700x400" alt=""/></a>
                        <div className="card-body">
                            <h4 className="card-title">
                                <a href="#">Project Four</a>
                            </h4>
                            <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam viverra euismod odio, gravida pellentesque urna varius vitae.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 portfolio-item">
                    <div className="card h-100">
                        <a href="#"><img className="card-img-top" src="http://placehold.it/700x400" alt=""/></a>
                        <div className="card-body">
                            <h4 className="card-title">
                                <a href="#">Project Five</a>
                            </h4>
                            <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam viverra euismod odio, gravida pellentesque urna varius vitae.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 portfolio-item">
                    <div className="card h-100">
                        <a href="#"><img className="card-img-top" src="http://placehold.it/700x400" alt=""/></a>
                        <div className="card-body">
                            <h4 className="card-title">
                                <a href="#">Project Six</a>
                            </h4>
                            <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque earum nostrum suscipit ducimus nihil provident, perferendis rem illo, voluptate atque, sit eius in voluptates, nemo repellat fugiat excepturi! Nemo, esse.</p>
                        </div>
                    </div>
                </div>
                */}
          </div>
        )
    }
}
export default SponsorPanels;