import React from 'react';
import './Resources.css';
import crest from 'img/contact/crest.jpg';

function CodeOfConductCoaches() {

  return (
    <div className='container club-resources-container'>
      <div className="resources-crest">
        <div>
          <img src={crest} width="150" height="215" alt="New Ross Celtic Crest" />
        </div>
        <div>
          <strong>Grounds:</strong><br/>Butlersland, New Ross,<br/>Co. Wexford, Y34 YN56
        </div>
      </div>
      <hr/>
      <div>
        <h1>COACHES/MENTORS CODE OF CONTACT</h1>
        <p className="club-social-media-content">
          Coaches/Mentors are critical to the establishment of good practices in football. Their attitude to the game directly informs the attitude of the players under their supervision. Coach/Mentors must speak and act in a manner consistent with this reality. As a Coach/Mentor I will:
          <ul>
            <li>Complete Garda Vetting before commencing my role and I will complete the safeguarding 1 course.</li>
            <li>Respect the rights, dignity and worth of each person and treat each person equally within the context of the sport.</li>
            <li>Not subscribe to the ethos of “win at all costs”. </li>
            <li>Place the health, well-being and safety of each player above all other considerations.</li>
            <li>Abide by the rules of the game.</li>
            <li>Strive to develop an appropriate working relationship with each player based on mutual respect.</li>
            <li>Have particular regard for the code when working with young players in the game.</li>
            <li>Not exert undue influence to obtain personal benefit or reward.</li>
            <li>Guide players to accept responsibility for their own behaviour and performance.</li>
            <li>Ensure that the activities and strategies they direct and advocate are appropriate for the age, maturity, experience and ability of players involved.</li>
            <li>Clarify with the players (and where the players are under the age of 18, their parents) exactly what is expected of them and also what they are entitled to expect from the Mentor.</li>
            <li>Co-operate with specialists within the game e.g. other coaches, officials, sport scientists, doctors, and physiotherapists, etc. in the best interest of players.</li>
            <li>Be conversant with the laws of the game and the rules of any competition in which a team under his/her stewardship is participating.</li>
            <li>Encourage players to adhere to the club’s code.</li>
            <li>Observe the highest standards of integrity and fair dealing.</li>
            <li>Promote a positive approach to the game e.g. fair play, honest endeavour and genuine teamwork.</li>
            <li>Not encourage or condone violations of the laws of the game, behaviour contrary to the spirit of the game, or the use of questionable strategies or tactics.</li>
            <li>Not encourage or condone the use of prohibited substances.</li>
            <li>Consistently display high standards of behaviour and comment.</li>
            <li>Use or tolerate the use of inappropriate language.</li>
            <li>Demonstrate due respect towards match officials.</li>
            <li>Accept the decisions of the match officials.</li>
          </ul>
        </p>
      </div>
    </div>
  );
}

export default CodeOfConductCoaches;