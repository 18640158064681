import {useState, useEffect} from 'react';
import axios from 'axios';
// Authentication Service from UserFront
import Userfront from "@userfront/react";

function RecordAttendanceModel() {

  /***********************************/
  // STATE SPACE
  //const [loggedIn, setLoggedIn] = useState([]);

  //useEffect(() => {
    // Any change to variable loggedIn, then do Whatever. May not need this here.
  //}, [loggedIn]);

  async function loadTeamsApi() {
    let result;
    await axios.get('/api/loadTeams', {
      headers: {
        "Authorization": `Bearer ${Userfront.accessToken()}`,
      }
    })
    .then((response) => {
      result = response;
    })
    .catch((error) => {
      console.log("RecordAttendance - Exception caught ", error);
      result = { "data" : {
        "result" : "false",
        "message" : "There was an issue loading Teams"
      }};
    });
    return result;
  }

  async function loadAttendanceRecordsApi(formRecord) {
    let result;
    console.log('Date is : ' + formRecord.inputs.attendanceDate + ' and team id is : ' + formRecord.inputs.teamId);
    await axios.get('/api/loadAttendanceRecords?attendanceDate=' + formRecord.inputs.attendanceDate + '&teamId=' + formRecord.inputs.teamId, {
      headers: {
        "Authorization": `Bearer ${Userfront.accessToken()}`,
      }
    })
    .then((response) => {
      result = response;
    })
    .catch((error) => {
      console.log("RecordAttendance - loadAttendanceRecordsApi - Exception caught ", error);
      result = { "data" : {
        "result" : "false",
        "message" : "There was an issue loading attendance records"
      }};
    });
    return result;
  }

  async function loadAllAttendanceTeamMembersApi(formRecord) {
    let result;
    //console.log('Date is : ' + formRecord.inputs.attendanceDate + ' and team id is : ' + formRecord.inputs.teamId);
    await axios.get('/api/loadAllAttendanceTeamMembers?teamId=' + formRecord.inputs.teamId + '&season=' + formRecord.inputs.season, {
      headers: {
        "Authorization": `Bearer ${Userfront.accessToken()}`,
      }
    })
    .then((response) => {
      result = response;
    })
    .catch((error) => {
      console.log("RecordAttendance - loadAllAttendanceTeamMembersApi - Exception caught ", error);
      result = { "data" : {
        "result" : "false",
        "message" : "There was an issue loading all attendance Team Members"
      }};
    });
    return result;
  }

  async function addSessionAttendanceRecord(formRecord) {
    let result;

    await axios.post('/api/addSessionAttendanceRecord', formRecord.inputs, {
      headers: {
        "Authorization": `Bearer ${Userfront.accessToken()}`,
      }})
    .then((response) => {
      console.log("Result from API is Id ", response);
      result = response;
    })
    .catch((error) => {
      console.log("RecordAttendanceModel - addSessionAttendanceRecord - Exception caught ", error);
      result = { "data" : {
        "result" : "false",
        "message" : "There was an issue adding attendance record"
      }};
    });
    return result;
  }

  async function viewAttendanceReportApi(formRecord) {
    let result;
    console.log('Loading all attendance records for report for season ' + formRecord.inputs.season + ' and team id ' + formRecord.inputs.teamId);
    await axios.get('/api/loadAttendanceReportRecords?season=' + formRecord.inputs.season + '&teamId=' + formRecord.inputs.teamId, {
      headers: {
        "Authorization": `Bearer ${Userfront.accessToken()}`,
      }
    })
    .then((response) => {
      result = response;
    })
    .catch((error) => {
      console.log("RecordAttendance - viewAttendanceReportApi - Exception caught ", error);
      result = { "data" : {
        "result" : "false",
        "message" : "There was an issue loading attendance report records"
      }};
    });
    return result;
  }

  async function viewAttendanceDatesForDaysApi(formRecord) {
    let result;
    console.log('Loading all attendance dates for players for team id ' + formRecord.inputs.teamId + ' and nbr days ' + formRecord.inputs.nbrDays);
    await axios.get('/api/loadAttendanceSessionDateRecordsByDays?teamId=' + formRecord.inputs.teamId + '&nbrDays=' + formRecord.inputs.nbrDays, {
      headers: {
        "Authorization": `Bearer ${Userfront.accessToken()}`,
      }
    })
    .then((response) => {
      result = response;
    })
    .catch((error) => {
      console.log("RecordAttendance - viewAttendanceDatesForDaysApi - Exception caught ", error);
      result = { "data" : {
        "result" : "false",
        "message" : "There was an issue loading attendance dates for nbr of days"
      }};
    });
    console.log('Result in viewAttendanceDatesForDaysApi is ' + result.data);
    return result;
  }

  async function viewAttendanceRecordsByPlayersDaysApi(formRecord) {
    let result;
    console.log('Loading all attendance records for players for team id ' + formRecord.inputs.teamId + ' and nbr days ' + formRecord.inputs.nbrDays);
    await axios.get('/api/loadAttendanceReportRecordsByDays?teamId=' + formRecord.inputs.teamId + '&nbrDays=' + formRecord.inputs.nbrDays, {
      headers: {
        "Authorization": `Bearer ${Userfront.accessToken()}`,
      }
    })
    .then((response) => {
      result = response;
    })
    .catch((error) => {
      console.log("RecordAttendance - viewAttendanceRecordsByPlayersDaysApi - Exception caught ", error);
      result = { "data" : {
        "result" : "false",
        "message" : "There was an issue loading attendance report records by players for days"
      }};
    });
    return result;
  }

  /*
  async function loginUserApi(formRecord) {
    let result;

    //await axios.get('/api/loginUser')
    await axios.post('/api/loginUser', formRecord.inputs)
    .then((response) => {
      console.log("Result from API is ", response);
      result = response;
      //setLoggedIn(response.data.data);
    })
    .catch((error) => {
      console.log("LoginModel - Exception caught ", error);
      result = { "data" : {
        "result" : "false" 
      }};
    });
    //result = { "data" : { "result" : "true" }};
    console.log("aaa", result);
    return result;
  }*/

    async function addNewsItemRecord(formRecord) {
      let result;
  
      await axios.post('/api/addNewsItemRecord', formRecord.inputs, {
        headers: {
          "Authorization": `Bearer ${Userfront.accessToken()}`,
        }})
      .then((response) => {
        console.log("Result from Insert News Item API is ", response);
        result = response;
      })
      .catch((error) => {
        console.log("NewsItemModel - addNewsItemRecord - Exception caught ", error);
        result = { "data" : {
          "result" : "false",
          "message" : "There was an issue adding news item record"
        }};
      });
      return result;
    }

  // Api space
  return {
    loadTeamsApi,
    loadAttendanceRecordsApi,
    loadAllAttendanceTeamMembersApi,
    addSessionAttendanceRecord,
    viewAttendanceReportApi,
    viewAttendanceDatesForDaysApi,
    viewAttendanceRecordsByPlayersDaysApi,
    addNewsItemRecord
  }
}

export default RecordAttendanceModel;