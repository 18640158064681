import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css"

/** JSCONFIG.JSON file has baseUrl specified so don't need to search back up directory */
import img41 from 'img/gallery/old_photos/img41.jpg';
import img41_tn from 'img/gallery/old_photos/img41_tn.jpg';
import img42 from 'img/gallery/old_photos/img42.jpg';
import img42_tn from 'img/gallery/old_photos/img42_tn.jpg';
import img43 from 'img/gallery/old_photos/img43.jpg';
import img43_tn from 'img/gallery/old_photos/img43_tn.jpg';
import img44 from 'img/gallery/old_photos/img44.jpg';
import img44_tn from 'img/gallery/old_photos/img44_tn.jpg';
import img45 from 'img/gallery/old_photos/img45.jpg';
import img45_tn from 'img/gallery/old_photos/img45_tn.jpg';
import img46 from 'img/gallery/old_photos/img46.jpg';
import img46_tn from 'img/gallery/old_photos/img46_tn.jpg';
import img47 from 'img/gallery/old_photos/img47.jpg';
import img47_tn from 'img/gallery/old_photos/img47_tn.jpg';
import img48 from 'img/gallery/old_photos/img48.jpg';
import img48_tn from 'img/gallery/old_photos/img48_tn.jpg';
import img49 from 'img/gallery/old_photos/img49.jpg';
import img49_tn from 'img/gallery/old_photos/img49_tn.jpg';
import img50 from 'img/gallery/old_photos/img50.jpg';
import img50_tn from 'img/gallery/old_photos/img50_tn.jpg';
import img51 from 'img/gallery/old_photos/img51.jpg';
import img51_tn from 'img/gallery/old_photos/img51_tn.jpg';
import img52 from 'img/gallery/old_photos/img52.jpg';
import img52_tn from 'img/gallery/old_photos/img52_tn.jpg';
import img53 from 'img/gallery/old_photos/img53.jpg';
import img53_tn from 'img/gallery/old_photos/img53_tn.jpg';
import img54 from 'img/gallery/old_photos/img54.jpg';
import img54_tn from 'img/gallery/old_photos/img54_tn.jpg';
import img55 from 'img/gallery/old_photos/img55.jpg';
import img55_tn from 'img/gallery/old_photos/img55_tn.jpg';
import img56 from 'img/gallery/old_photos/img56.jpg';
import img56_tn from 'img/gallery/old_photos/img56_tn.jpg';
import img57 from 'img/gallery/old_photos/img57.jpg';
import img57_tn from 'img/gallery/old_photos/img57_tn.jpg';
import img58 from 'img/gallery/old_photos/img58.jpg';
import img58_tn from 'img/gallery/old_photos/img58_tn.jpg';
import img59 from 'img/gallery/old_photos/img59.jpg';
import img59_tn from 'img/gallery/old_photos/img59_tn.jpg';
import img60 from 'img/gallery/old_photos/img60.jpg';
import img60_tn from 'img/gallery/old_photos/img60_tn.jpg';
import img61 from 'img/gallery/old_photos/img61.jpg';
import img61_tn from 'img/gallery/old_photos/img61_tn.jpg';

let imgs = [[img41,img41_tn], [img42,img42_tn], [img43,img43_tn], [img44,img44_tn], [img45,img45_tn],
            [img46,img46_tn], [img47,img47_tn], [img48,img48_tn], [img49,img49_tn], [img50,img50_tn],
            [img51,img51_tn], [img52,img52_tn], [img53,img53_tn], [img54,img54_tn], [img55,img55_tn],
            [img56,img56_tn], [img57,img57_tn], [img58,img58_tn], [img59,img59_tn], [img60,img60_tn],
            [img61,img61_tn]];
let images = [];
imgs.forEach((item, index) => {
  images.push({ original: item[0].toString(), thumbnail: item[1].toString() });
});

export default class GalleryThree extends Component {

  render() {
    
    return (
      <div>
        <span>** All Photos on this page were supplied to New Ross Celtic by Mick Breen and we would like to thank him for allowing us to use these. **</span>
        <div>
          <ImageGallery items={images} />;
        </div>
      </div>
    );
  }
}