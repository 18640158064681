import React, { Component } from 'react';

class Facebook extends Component {
    render() {
        return (
            <div className="col-lg-4 mb-4">
                <div className="card h-100">
                    <h4 className="card-header">Facebook Posts</h4>
                    <div className="card-body">
                    <div className="fb-page" data-href="https://www.facebook.com/newrossceltic/" data-tabs="timeline" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
                        <blockquote cite="https://www.facebook.com/newrossceltic/" className="fb-xfbml-parse-ignore">
                            <a href="https://www.facebook.com/newrossceltic/">New Ross Celtic AFC</a>
                        </blockquote>
                      </div>
                      <p className="card-text">&nbsp;</p>
                    </div>
                    <div className="card-footer">
                        <a href="https://www.facebook.com/newrossceltic/" className="btn btn-primary">Visit Ross Celtic Facebook Page</a>
                    </div>
                </div>
            </div>
        )
    }
}
export default Facebook;