import {useState, useEffect} from 'react';
import axios from 'axios';
// Authentication Service from UserFront
import Userfront from "@userfront/react";

function NewsItemModel() {

  async function addNewsItemRecord(formRecord) {
    let result;

    await axios.post('/api/addNewsItemRecord', formRecord.inputs, {
      headers: {
        "Authorization": `Bearer ${Userfront.accessToken()}`,
      }})
    .then((response) => {
      console.log("Result from Insert News Item API is ", response);
      result = response;
    })
    .catch((error) => {
      console.log("NewsItemModel - addNewsItemRecord - Exception caught ", error);
      result = { "data" : {
        "result" : "false",
        "message" : "There was an issue adding news item record"
      }};
    });
    return result;
  }

  // Api space
  return {
    addNewsItemRecord
  }
}

export default NewsItemModel;