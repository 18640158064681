import React, { Component } from 'react';
/** JSCONFIG.JSON file has baseUrl specified so don't need to search back up directory */
import miniSponsor1 from 'img/sponsors-mini/AnnMcDonalds.png';
import miniSponsor2 from 'img/sponsors-mini/BarrowsideVeterinaryHospital.png';
import miniSponsor3 from 'img/sponsors-mini/BoylesButchers.png';
import miniSponsor4 from 'img/sponsors-mini/ByrneMemorials.png';
import miniSponsor5 from 'img/sponsors-mini/SinnottsCentraIrishtown.png';
import miniSponsor6 from 'img/sponsors-mini/DavidQuigleyAutos.png';
import miniSponsor7 from 'img/sponsors-mini/DenisesBeautyClinic.png';
import miniSponsor8 from 'img/sponsors-mini/DkFuels.png';
import miniSponsor9 from 'img/sponsors-mini/JamesMurphyRoofing.png';
import miniSponsor10 from 'img/sponsors-mini/JoycesHomeCentre.png';
import miniSponsor11 from 'img/sponsors-mini/MilltownBuildingServices.png';
import miniSponsor12 from 'img/sponsors-mini/PetMasterNewRoss.png';
import miniSponsor13 from 'img/sponsors-mini/PwpCleanMachines.png';
import miniSponsor14 from 'img/sponsors-mini/TrudiesKitchen.png';

class SponsorPanelsMini extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-lg-2 col-sm-3 col-4 portfolio-item">
                    <div className="card h-100">
                        <a href="https://www.annmcdonaldscafe.com/" target="_blank" rel="noopener noreferrer"><img className="card-img-top" src={miniSponsor1} alt="Ann McDonalds Cafe & Bistro"/></a>
                    </div>
                </div>
                <div className="col-lg-2 col-sm-3 col-4 portfolio-item">
                    <div className="card h-100">
                        <a href="http://www.barrowsidevets.com/" target="_blank" rel="noopener noreferrer"><img className="card-img-top" src={miniSponsor2} alt="Barrowside Veterinary Hospital"/></a>
                    </div>
                </div>
                <div className="col-lg-2 col-sm-3 col-4 portfolio-item">
                    <div className="card h-100">
                        <a href="https://www.facebook.com/pages/category/Butcher-Shop/Boyles-Butchers-2013244635594844/" target="_blank" rel="noopener noreferrer"><img className="card-img-top" src={miniSponsor3} alt="Boyle's Butchers New Ross"/></a>
                    </div>
                </div>
                <div className="col-lg-2 col-sm-3 col-4 portfolio-item">
                    <div className="card h-100">
                        <a href="https://byrnememorials.ie/" target="_blank" rel="noopener noreferrer"><img className="card-img-top" src={miniSponsor4} alt="Byrne Memorials"/></a>
                    </div>
                </div>
                <div className="col-lg-2 col-sm-3 col-4 portfolio-item">
                    <div className="card h-100">
                        <a href="https://www.facebook.com/pages/category/Supermarket/Sinnotts-Centra-Irishtown-New-Ross-489274901472223/" target="_blank" rel="noopener noreferrer"><img className="card-img-top" src={miniSponsor5} alt="Sinnott's Centra Irishtown New Ross"/></a>
                    </div>
                </div>
                <div className="col-lg-2 col-sm-3 col-4 portfolio-item">
                    <div className="card h-100">
                        <a href="https://www.davidquigleyautos.ie/" target="_blank" rel="noopener noreferrer"><img className="card-img-top" src={miniSponsor6} alt="David Quigley Autos"/></a>
                    </div>
                </div>
                <div className="col-lg-2 col-sm-3 col-4 portfolio-item">
                    <div className="card h-100">
                        <a href="https://denisesbeautyclinic.ie/" target="_blank" rel="noopener noreferrer"><img className="card-img-top" src={miniSponsor7} alt="Denise's Beauty Clinic"/></a>
                    </div>
                </div>
                <div className="col-lg-2 col-sm-3 col-4 portfolio-item">
                    <div className="card h-100">
                        <a href="https://www.facebook.com/pages/category/Community/D-K-Fuels-146776368717990/" target="_blank" rel="noopener noreferrer"><img className="card-img-top" src={miniSponsor8} alt="DK Fuels"/></a>
                    </div>
                </div>
                <div className="col-lg-2 col-sm-3 col-4 portfolio-item">
                    <div className="card h-100">
                        <a href="https://www.google.ie/" target="_blank" rel="noopener noreferrer"><img className="card-img-top" src={miniSponsor9} alt="James Murphy Roofing"/></a>
                    </div>
                </div>
                <div className="col-lg-2 col-sm-3 col-4 portfolio-item">
                    <div className="card h-100">
                        <a href="https://www.joyceshomecentre.ie/" target="_blank" rel="noopener noreferrer"><img className="card-img-top" src={miniSponsor10} alt="Joyces Home Centre"/></a>
                    </div>
                </div>
                <div className="col-lg-2 col-sm-3 col-4 portfolio-item">
                    <div className="card h-100">
                        <a href="https://www.facebook.com/Milltown-Building-Services-276344663258145/" target="_blank" rel="noopener noreferrer"><img className="card-img-top" src={miniSponsor11} alt="Milltown Building Services"/></a>
                    </div>
                </div>
                <div className="col-lg-2 col-sm-3 col-4 portfolio-item">
                    <div className="card h-100">
                        <a href="https://www.facebook.com/petmasternewross/" target="_blank" rel="noopener noreferrer"><img className="card-img-top" src={miniSponsor12} alt="Pet Master New Ross"/></a>
                    </div>
                </div>
                <div className="col-lg-2 col-sm-3 col-4 portfolio-item">
                    <div className="card h-100">
                        <a href="https://cleanmachines.ie/" target="_blank" rel="noopener noreferrer"><img className="card-img-top" src={miniSponsor13} alt="PWP Cleanmachines"/></a>
                    </div>
                </div>
                <div className="col-lg-2 col-sm-3 col-4 portfolio-item">
                    <div className="card h-100">
                        <a href="https://www.trudieskitchen.com/" target="_blank" rel="noopener noreferrer"><img className="card-img-top" src={miniSponsor14} alt="Trudies Catering Kitchen"/></a>
                    </div>
                </div>
            </div>
        )
    }
}
export default SponsorPanelsMini;