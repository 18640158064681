import React, { Component } from 'react';
/** JSCONFIG.JSON file has baseUrl specified so don't need to search back up directory */
import img1 from 'img/carousel1.jpg';
import img2 from 'img/welcome.png';
import img3 from 'img/U13-Boys-web.jpg';
import img4 from 'img/U14-Boys-web.jpg';
import img5 from 'img/U11-Boys-web.jpg';

class CarouselMainPage extends Component {
    render() {
        return (
            /* ADD data-interval="3000" to set the speed of carousel to 3 seconds */
            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" data-interval="3000">
                <ol className="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                </ol>
                <div className="carousel-inner" role="listbox">
                    {/* Slide One - Set the background image for this slide in the line below */}
                    <div className="carousel-item active" style={{ backgroundImage: "url(" + img5 + ")" }}>
                    <div className="carousel-caption d-none d-md-block">
                            <h3>New Ross Celtic U11 School Boys</h3>
                            <p>Training - All welcome</p>
                        </div>
                    </div>
                    {/* Slide Two - Set the background image for this slide in the line below */}
                    {/* Image should be 1900 x 1080 */}
                    <div className="carousel-item" style={{ backgroundImage: "url(" + img1 + ")" }} >
                        <div className="carousel-caption d-none d-md-block">
                            <h3>New Ross Celtic School Girls</h3>
                            <p>Teams to suit all ages</p>
                        </div>
                    </div>
                    {/* Slide Three - Set the background image for this slide in the line below */}
                    <div className="carousel-item" style={{ backgroundImage: "url(" + img3 + ")" }}>
                    <div className="carousel-caption d-none d-md-block">
                            <h3>New Ross Celtic U13 School Boys</h3>
                            <p>Training - All welcome</p>
                        </div>
                    </div>
                    {/* Slide Four - Set the background image for this slide in the line below */}
                    <div className="carousel-item" style={{ backgroundImage: "url(" + img4 + ")" }}>
                        <div className="carousel-caption d-none d-md-block">
                            <h3>New Ross Celtic U14 School Boys</h3>
                            <p>Training - All welcome</p>
                        </div>
                    </div>
                    {/* Slide Five - Set the background image for this slide in the line below */}
                    <div className="carousel-item" style={{ backgroundImage: "url(" + img2 + ")" }}>
                        <div className="carousel-caption d-none d-md-block">
                        </div>
                    </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>
        )
    }
}
export default CarouselMainPage;